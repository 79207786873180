import { nanoid } from "nanoid";
import { createDoor, moveDoor } from "../../actions";
import DungeonTool from "./DungeonTool";

export default class AddDoorTool extends DungeonTool {
    private doorId?: string;

    public deactivate() {
        // TODO: if doorId { deleteDoor }
        this.view.clearSelection();
    }

    public handleClick(ev: React.MouseEvent<HTMLCanvasElement> | React.Touch) {
        if ((ev as any).preventDefault) {
            (ev as any).preventDefault();
        }

        const p = this.view.clientToPoint(ev);
        if (this.doorId != null) {
            this.dispatch(moveDoor(this.view.props.dungeonId, this.doorId, "to", p));
            this.view.clearSelection();
            this.doorId = undefined;
        } else {
            this.doorId = nanoid();
            this.dispatch(createDoor(this.view.props.dungeonId, this.doorId, p, p));
            this.view.select(this.doorId);
        }
    }

    public handleMouseMove(ev: React.MouseEvent<HTMLCanvasElement>) {
        if (this.doorId != null) {
            const p = this.view.clientToPoint(ev);
            this.dispatch(moveDoor(this.view.props.dungeonId, this.doorId, "to", p));
        } else {
            DungeonTool.prototype.handleMouseMove.call(this, ev);
        }
    }
}
