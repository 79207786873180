import * as React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { Route, RouteProps } from "react-router-dom";
import { RootState } from "../store";
import NotAuthenticated from "./NotAuthenticated";

export default function PrivateRoute<T extends RouteProps = RouteProps>({
    children,
    fallback,
    ...rest
}: T & { fallback?: JSX.Element | null }): JSX.Element | null {
    const auth = useSelector((state: RootState) => state.firebase.auth);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isLoaded(auth) && !isEmpty(auth)) {
                    return rest.render ? rest.render(props) : children;
                } else {
                    return fallback !== undefined ? fallback : <NotAuthenticated open />;
                }
            }}
        />
    );
}
