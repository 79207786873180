import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.modal + 50,
    },
}));

export default function Progress(): JSX.Element {
    const progress = useSelector((state: RootState) => state.progress);
    const classes = useStyles();

    return (
        <Backdrop open={progress.open} classes={classes}>
            {progress.message && <h2>{progress.message}</h2>}
            <CircularProgress
                variant={progress.value != null ? "determinate" : "indeterminate"}
                value={progress.value}
            />
        </Backdrop>
    );
}
