import { Point } from "../lib/geometry";
import DungeonTool from "./DungeonTool";

export default class SetGridTool extends DungeonTool {
    private gridAnchor?: Point;

    public activate(): void {
        this.view.setState({ showGrid: true });
    }

    public deactivate(): void {
        this.view.setState({ showGrid: false });
    }

    public handleClick(ev: React.MouseEvent<HTMLCanvasElement> | React.Touch): void {
        const isEvent = (
            x: React.MouseEvent<HTMLCanvasElement> | React.Touch
        ): x is React.MouseEvent<HTMLCanvasElement> => {
            return (x as any).preventDefault != null;
        };

        if (isEvent(ev) && ev.button !== 0) {
            return DungeonTool.prototype.handleClick.call(this, ev);
        }

        const p = this.view.clientToPoint(ev);
        this.gridAnchor = p;
        const oldScale = this.view.props.dungeon.gridScale;
        this.view.props.firebase.set(`/dungeons/${this.view.props.dungeonId}/gridOffset`, this.gridOffset(p, oldScale));
    }

    public handleWheel(ev: React.WheelEvent<HTMLCanvasElement>): void {
        ev.preventDefault();

        const oldScale = this.view.props.dungeon.gridScale;
        const oldOffset = this.view.props.dungeon.gridOffset || { x: 0.0, y: 0.0 };

        if (this.gridAnchor == null) {
            const p = this.view.clientToPoint(ev);
            this.gridAnchor = this.roundToGrid(p, oldOffset, oldScale);
        }

        const newScale = Math.round(Math.max(oldScale + Math.sign(ev.deltaY), 5.0));
        this.view.props.firebase.update(`/dungeons/${this.view.props.dungeonId}`, {
            gridScale: newScale,
            gridOffset: this.gridOffset(this.gridAnchor, newScale),
        });
    }

    public handlePinch(ev: TouchEvent, from: Point, to: Point) {
        const gridScale = Math.round(Math.max(Math.abs(to.x - from.x), Math.abs(to.y - from.y), 5.0));
        this.view.props.firebase.update(`/dungeons/${this.view.props.dungeonId}`, {
            gridScale,
            gridOffset: this.gridOffset(from, gridScale),
        });
    }

    private roundToGrid(p: Point, gridOffset: Point, gridScale: number): Point {
        return {
            x: (Math.round((p.x - gridOffset.x) / gridScale - 0.5) + 0.5) * gridScale + gridOffset.x,
            y: (Math.round((p.y - gridOffset.y) / gridScale - 0.5) + 0.5) * gridScale + gridOffset.y,
        };
    }

    private gridOffset(p: Point, scale: number): Point {
        const offset = {
            x: Math.round(((p.x % scale) - 0.5 * scale) % scale),
            y: Math.round(((p.y % scale) - 0.5 * scale) % scale),
        };

        if (offset.x < 0) {
            offset.x += scale;
        }
        if (offset.y < 0) {
            offset.y += scale;
        }

        return offset;
    }
}
