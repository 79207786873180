import { Dispatch } from "../../store";
import { Dungeon } from "../Dungeon";
import { ClientPoint, Point, Segment } from "../lib/geometry";
import normalizeWheel from "normalize-wheel";

export default abstract class DungeonTool {
    protected referencePoint?: Point;
    protected referenceScale = 0;
    protected tapOrClick = false;

    constructor(public view: Dungeon, public dispatch: Dispatch) {}

    public activate(): void {
        /* No-op */
    }

    public deactivate(): void {
        /* No-op */
    }

    public handleClick(ev: React.MouseEvent<HTMLCanvasElement> | React.Touch): void {
        /* No-op */
    }

    public handleMouseDown(ev: React.MouseEvent<HTMLCanvasElement>): void {
        if (ev.button === 0 || ev.button === 1) {
            this.tapOrClick = true;
            this.referencePoint = this.view.clientToPoint(ev);
        }
    }

    public handleMouseMove(ev: React.MouseEvent<HTMLCanvasElement>): void {
        this.tapOrClick = false;

        if ((ev.buttons === 1 || ev.buttons === 4) && this.referencePoint) {
            this.view.setState({ panningOffset: this.panningOffsetForPoint(this.referencePoint, ev) });
        }
    }

    public handleMouseUp(ev: React.MouseEvent<HTMLCanvasElement>): void {
        if (ev.button === 0 || ev.button === 1) {
            this.referencePoint = undefined;
            if (this.tapOrClick) {
                this.tapOrClick = false;
                this.handleClick(ev);
            }
        }
    }

    public handleMouseOut(ev: React.MouseEvent<HTMLCanvasElement>): void {
        this.referencePoint = undefined;
    }

    public handleWheel(ev: React.WheelEvent<HTMLCanvasElement>): void {
        ev.preventDefault();

        const p = this.view.clientToPoint(ev);
        const normalized = normalizeWheel(ev);
        const scale = Math.max(0.25, Math.min(this.view.state.scale * Math.pow(1.05, -normalized.pixelY / 10), 10.0));
        const panningOffset = this.panningOffsetForPoint(p, ev, scale);

        this.view.setState({ scale, panningOffset });
    }

    public handleContextMenu(ev: React.MouseEvent<HTMLCanvasElement>): void {
        ev.preventDefault();
    }

    public handleTouchStart(ev: TouchEvent): void {
        ev.preventDefault();

        if (ev.touches.length === 1) {
            this.referencePoint = this.view.clientToPoint(ev.changedTouches[0]);
            this.tapOrClick = true;
        } else {
            this.tapOrClick = false;
            if (ev.touches.length === 2) {
                const s = {
                    from: this.view.clientToPoint(ev.touches[0]),
                    to: this.view.clientToPoint(ev.touches[1]),
                };
                const l = Segment.size({
                    from: { x: ev.touches[0].clientX, y: ev.touches[0].clientY },
                    to: { x: ev.touches[1].clientX, y: ev.touches[1].clientY },
                });
                this.referencePoint = Segment.interpolate(s, 0.5);
                this.referenceScale = l / this.view.state.scale;
            }
        }
    }

    public handleTouchMove(ev: TouchEvent): void {
        ev.preventDefault();
        this.tapOrClick = false;

        if (ev.touches.length === 1 && this.referencePoint) {
            // Panning
            const panningOffset = this.panningOffsetForPoint(this.referencePoint, ev.changedTouches[0]);
            this.view.setState({ panningOffset });
        } else if (ev.touches.length === 2 && this.referencePoint) {
            // Pinching
            this.handlePinch(ev, this.view.clientToPoint(ev.touches[0]), this.view.clientToPoint(ev.touches[1]));
        }
    }

    public handlePinch(ev: TouchEvent, from: Point, to: Point) {
        const s = { from, to };
        const l = Segment.size({
            from: { x: ev.touches[0].clientX, y: ev.touches[0].clientY },
            to: { x: ev.touches[1].clientX, y: ev.touches[1].clientY },
        });
        const mp = {
            clientX: 0.5 * (ev.touches[0].clientX + ev.touches[1].clientX),
            clientY: 0.5 * (ev.touches[0].clientY + ev.touches[1].clientY),
        };
        const scale = Math.max(0.25, Math.min(l / this.referenceScale, 10.0));
        const panningOffset = this.panningOffsetForPoint(this.referencePoint!, mp, scale);
        this.view.setState({ scale, panningOffset });
    }

    public handleTouchEnd(ev: TouchEvent): void {
        ev.preventDefault();

        if (ev.touches.length === 0) {
            this.referencePoint = undefined;
            if (this.tapOrClick) {
                this.tapOrClick = false;
                this.handleClick(ev.changedTouches[0]);
            }
        } else if (ev.touches.length === 1) {
            this.referencePoint = this.view.clientToPoint(ev.touches[0]);
            this.referenceScale = 0;
        }
    }

    private panningOffsetForPoint(p: Point, cp: ClientPoint, scale = this.view.state.scale): Point {
        const fromCenter = Point.difference(this.view.clientToPoint(cp), this.view.state.panningOffset);
        const scaled = Point.scale(fromCenter, this.view.state.scale / scale);
        return Point.difference(p, scaled);
    }
}
