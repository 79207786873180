import { Quadtree } from "../../../core/Cargo.toml";
import { moveDoor, moveToken, openDoor } from "../../actions";
import { Dispatch, DoorData, DungeonTokenData } from "../../store";
import { Dungeon } from "../Dungeon";
import { Box, ClientPoint, Point, Segment } from "../lib/geometry";
import { canControl, isGameMaster, isPlayer } from "../lib/utils";
import DungeonTool from "./DungeonTool";

abstract class Mover {
    public abstract move(p: Point): void;
    public finish(): void {
        /* Do nothing */
    }
    public cancel(): void {
        /* Do nothing */
    }
}

function doorCollision(doors: DoorData[] | undefined, token: Box): boolean {
    return (
        doors != null &&
        doors.some(
            (door) => !door.open && Box.intersect(token, Segment.bounds(door)) && Box.segmentIntersects(token, door)
        )
    );
}

function collides(box: Box, quadtree: Quadtree, doors: DoorData[]): boolean {
    return quadtree.collides(box) || doorCollision(doors, box);
}

class TokenMover extends Mover {
    constructor(public view: Dungeon, public dispatch: Dispatch, public key: string) {
        super();
    }

    public move(p: Point) {
        if (isGameMaster(this.view.props.player)) {
            this.setPosition(p);
            return;
        }

        console.time("move");
        const quadtree = this.view.state.quadtree;
        const token = this.view.props.tokens[this.key];
        const pos = token.pos;
        if (quadtree && (pos.x !== p.x || pos.y !== p.y)) {
            const doors = Object.values(this.view.props.doors);
            const gridScale = this.view.props.dungeon.gridScale;
            const step_size = 0.05 * gridScale;
            const d = Point.distance(pos, p);

            let p_last = pos;
            if (d >= 1.5 * step_size) {
                const delta = Point.scale(Point.normalize(Point.difference(p, pos)), step_size);
                const steps = Math.floor(d / step_size);
                const box = this.hitBox(token, pos);
                for (let i = 0; i < steps; i++) {
                    box.x += delta.x;
                    box.y += delta.y;
                    if (collides(box, quadtree, doors)) {
                        this.setPosition(p_last);
                        console.timeEnd("move");
                        return;
                    } else {
                        p_last = Point.offset(p_last, delta);
                    }
                }
            }

            const box = this.hitBox(token, p);
            if (collides(box, quadtree, doors)) {
                this.setPosition(p_last);
            } else {
                this.setPosition(p);
            }
        }
        console.timeEnd("move");
    }

    public finish() {
        const token = this.view.props.tokens[this.key];
        const p = this.view.snapToGrid(token.size, token.pos);

        const quadtree = this.view.state.quadtree;
        const doors = Object.values(this.view.props.doors);
        if (
            (token.pos.x === p.x && token.pos.y === p.y) ||
            (quadtree && collides(this.hitBox(token, p), quadtree, doors))
        ) {
            return;
        }

        this.setPosition(p);
    }

    private hitBox(token: DungeonTokenData, p: Point): Box {
        const gridScale = this.view.props.dungeon.gridScale;
        const w = token.size.x * gridScale * 0.35;
        const h = token.size.y * gridScale * 0.35;
        return { x: p.x - w / 2, y: p.y - h / 2, w, h };
    }

    private setPosition(p: Point) {
        this.dispatch(moveToken(this.view.props.dungeonId, this.key, p));
    }
}

class DoorMover extends Mover {
    constructor(public view: Dungeon, public dispatch: Dispatch, public key: string, public from: boolean) {
        super();
        this.view.select(key);
    }

    public move(p: Point) {
        this.dispatch(moveDoor(this.view.props.dungeonId, this.key, this.from ? "from" : "to", p));
    }

    public finish() {
        this.view.deselect(this.key);
    }

    public cancel() {
        this.view.deselect(this.key);
    }
}

export default class SelectTool extends DungeonTool {
    private mover?: Mover;
    private didMove = false;

    public handleClick(ev: React.MouseEvent<HTMLCanvasElement> | React.Touch) {
        const isEvent = (
            x: React.MouseEvent<HTMLCanvasElement> | React.Touch
        ): x is React.MouseEvent<HTMLCanvasElement> => {
            return (x as any).preventDefault != null;
        };

        if (isEvent(ev)) {
            if (ev.button !== 0) {
                return;
            }
            ev.preventDefault();
        }

        if (this.didMove || isPlayer(this.view.props.player)) {
            return;
        }

        const p = this.view.clientToPoint(ev);
        const doors = Object.entries(this.view.props.doors || {});
        const closestDoor = doors
            .map(([key, door]) => {
                const dist = Point.sqrDistance(Segment.interpolate(door, 0.5), p);
                return { dist, key, open: door.open };
            })
            .sort((a, b) => a.dist - b.dist)[0];

        if (closestDoor && closestDoor.dist < 48 * 48) {
            this.dispatch(openDoor(this.view.props.dungeonId, closestDoor.key, !closestDoor.open));
            // TODO: Error handling
        }
    }

    public handleMouseDown(ev: React.MouseEvent<HTMLCanvasElement>) {
        if (ev.button === 0) {
            ev.preventDefault();
            this.startMove(ev, 5);
        }

        if (this.mover == null) {
            DungeonTool.prototype.handleMouseDown.call(this, ev);
        }
    }

    public handleMouseMove(ev: React.MouseEvent<HTMLCanvasElement>) {
        ev.preventDefault();

        if ((ev.buttons & 1) !== 0) {
            this.didMove = true;
        }

        if (this.mover == null) {
            DungeonTool.prototype.handleMouseMove.call(this, ev);
        } else {
            this.move(ev);
        }
    }

    public handleMouseUp(ev: React.MouseEvent<HTMLCanvasElement>) {
        if (ev.button === 0) {
            ev.preventDefault();
            if (this.mover == null) {
                DungeonTool.prototype.handleMouseUp.call(this, ev);
            } else {
                this.finishMove();
            }
        }
    }

    public handleMouseOut(ev: React.MouseEvent<HTMLCanvasElement>) {
        if (this.mover == null) {
            DungeonTool.prototype.handleMouseOut.call(this, ev);
        } else {
            ev.preventDefault();
            this.mover.cancel();
            this.mover = undefined;
        }
    }

    public handleContextMenu(ev: React.MouseEvent<HTMLCanvasElement>): void {
        DungeonTool.prototype.handleContextMenu.call(this, ev);
    }

    public handleTouchStart(ev: TouchEvent) {
        ev.preventDefault();
        if (ev.touches.length === 1) {
            this.startMove(ev.changedTouches[0], 48);
        } else if (this.mover != null) {
            this.mover.cancel();
            this.mover = undefined;
        }

        if (this.mover == null) {
            DungeonTool.prototype.handleTouchStart.call(this, ev);
        }
    }

    public handleTouchMove(ev: TouchEvent) {
        ev.preventDefault();
        if (ev.touches.length === 1 && this.mover != null) {
            const touch = ev.changedTouches[0];
            this.move(touch);
            this.didMove = true;
        } else {
            DungeonTool.prototype.handleTouchMove.call(this, ev);
        }
    }

    public handleTouchEnd(ev: TouchEvent) {
        ev.preventDefault();
        if (ev.changedTouches.length === 1 && ev.touches.length === 0 && this.mover != null) {
            const touch = ev.changedTouches[0];
            this.finishMove();
            if (!this.didMove) {
                this.handleClick(touch);
            }
        } else {
            DungeonTool.prototype.handleTouchEnd.call(this, ev);
        }
    }

    private startMove(cp: ClientPoint, rMin: number) {
        const scale = this.view.state.scale;
        const gridScale = this.view.props.dungeon.gridScale;
        const p = this.view.clientToPoint(cp);
        const selectables: Array<[number, (...args: any[]) => void, any[]]> = [];

        const rMinScaled = rMin / scale;
        for (const [key, token] of Object.entries(this.view.props.tokens).filter(([_, t]) =>
            canControl(t, this.view.props.player)
        )) {
            const w = token.size.x * gridScale;
            const h = token.size.y * gridScale;
            if (Box.contains({ x: token.pos.x - w / 2, y: token.pos.y - h / 2, w, h }, p)) {
                const d = Point.sqrDistance(p, token.pos);
                selectables.push([
                    d,
                    () => {
                        this.view.setState({ selectedToken: key });
                        this.mover = new TokenMover(this.view, this.dispatch, key);
                    },
                    [],
                ]);
            }
        }

        if (isGameMaster(this.view.props.player)) {
            const doors = Object.entries(this.view.props.doors);
            const doorMover = (key: string, from: boolean) => {
                this.mover = new DoorMover(this.view, this.dispatch, key, from);
            };

            for (const [key, door] of doors) {
                const dFrom = Point.sqrDistance(p, door.from);
                const dTo = Point.sqrDistance(p, door.to);
                const r = Math.max(rMinScaled, 5);
                if (dFrom < r * r) {
                    selectables.push([dFrom, doorMover, [key, true]]);
                }
                if (dTo < r * r) {
                    selectables.push([dTo, doorMover, [key, false]]);
                }
            }
        }

        const selection = selectables.sort((a, b) => a[0] - b[0])[0];
        if (selection != null) {
            selection[1](...selection[2]);
        }
        this.didMove = false;
    }

    private move(cp: ClientPoint) {
        if (this.mover) {
            this.mover.move(this.view.clientToPoint(cp));
        }
    }

    private finishMove() {
        if (this.mover) {
            if (this.didMove) {
                this.mover.finish();
            } else {
                this.mover.cancel();
            }
            this.mover = undefined;
        }
    }
}
