import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import { TorchlitAction } from "../actions/types";
import { AlertState, ModalState, ProgressState, RootState } from "./types";

const initialModalState: ModalState = { type: undefined };

function modalReducer(state = initialModalState, action: TorchlitAction): ModalState {
    switch (action.type) {
        case "SHOW_MODAL":
            return {
                type: action.modalType,
                open: true,
                props: action.props,
            };
        case "HIDE_MODAL":
            return { ...state, open: false };
        case "REMOVE_MODAL":
            return initialModalState;
        default:
            return state;
    }
}

function alertReducer(state: AlertState = [], action: TorchlitAction): AlertState {
    switch (action.type) {
        case "SHOW_ALERT":
            return [
                ...state,
                {
                    key: action.key,
                    open: true,
                    severity: action.severity,
                    message: action.message,
                },
            ];
        case "HIDE_ALERT":
            return state.map((x) => (x.key === action.key ? { ...x, open: false } : x));
        case "REMOVE_ALERT":
            return state.filter((x) => x.key !== action.key);
        default:
            return state;
    }
}

function progressReducer(state: ProgressState = { open: false }, action: TorchlitAction): ProgressState {
    switch (action.type) {
        case "SHOW_PROGRESS":
            return { open: true, message: action.message, value: action.value };
        case "HIDE_PROGRESS":
            return { ...state, open: false };
        default:
            return state;
    }
}

const rootReducer = combineReducers<RootState>({
    firebase: firebaseReducer,
    modal: modalReducer,
    progress: progressReducer,
    alerts: alertReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
