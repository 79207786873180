import { MuiThemeProvider } from "@material-ui/core/styles";
import * as firebase from "firebase/app";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { isEmpty, isLoaded, ReactReduxFirebaseProvider } from "react-redux-firebase";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loading from "./components/Loading";
import * as config from "./config";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import configureStore from "./store";
import theme from "./theme";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const initialState = {};
const store = configureStore(initialState);

// Initialize Firebase instance
firebase.initializeApp(config.firebase);

const reduxFirebaseProps = {
    firebase,
    config: config.reduxFirebase,
    dispatch: store.dispatch,
};

function AuthIsLoaded({ children }: any) {
    const { auth, profile } = useSelector((state: any) => ({
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }));

    if (!isLoaded(auth) || (!isEmpty(auth) && !isLoaded(profile))) {
        return <Loading open />;
    }

    return children;
}

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...reduxFirebaseProps}>
                <DndProvider backend={HTML5Backend}>
                    <AuthIsLoaded>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </AuthIsLoaded>
                </DndProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById("root") as HTMLElement
);

registerServiceWorker();
