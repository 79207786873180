import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { showModal } from "../actions";
import { GameData } from "../store";

const useStyles = makeStyles((theme: Theme) => ({
    addItem: {
        position: "sticky",
        // this is theme.shadows[1] with increased negative spread and vertical offset so it is only visible
        // below the box, not to the sides
        boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.14),0px 4px 3px -3px rgba(0,0,0,0.12)",
    },
    list: {
        flexGrow: 1,
        overflowY: "auto",
    },
}));

export default function DungeonsList({ gameId, game }: { gameId: string; game: GameData }): JSX.Element | null {
    const firebase = useFirebase();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch<{ dungeonId: string }>(`/games/${gameId}/d/:dungeonId`);
    const dispatch = useDispatch();

    const dungeonEntries = Object.entries(game.dungeons).sort((a, b) => a[1].localeCompare(b[1]));

    const activateDungeon = (dungeonId: string) => {
        firebase.set(`games/${gameId}/activeDungeon`, dungeonId);
        if (dungeonId === match?.params.dungeonId) {
            history.replace(`/games/${gameId}`);
        }
    };

    const currentDungeon = match?.params.dungeonId || game?.activeDungeon;

    return (
        <>
            <List disablePadding>
                <ListItem
                    button
                    className={classes.addItem}
                    onClick={() => dispatch(showModal("CREATE_DUNGEON", { gameId }))}
                >
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText>New map</ListItemText>
                </ListItem>
            </List>

            <List className={classes.list}>
                {dungeonEntries.map(([key, name]) => (
                    <ListItem
                        button
                        key={key}
                        selected={key === currentDungeon}
                        component={Link}
                        to={key === game.activeDungeon ? `/games/${gameId}` : `/games/${gameId}/d/${key}`}
                    >
                        <ListItemText>{name}</ListItemText>
                        <ListItemSecondaryAction>
                            {key === game.activeDungeon ? (
                                <Star />
                            ) : (
                                <IconButton edge="end" onClick={() => activateDungeon(key)}>
                                    <StarBorder color="disabled" />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </>
    );
}
