import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function NotAuthorized({ open }: { open: boolean }): JSX.Element {
    return (
        <Backdrop open={open}>
            <h1>You need to sign in first.</h1>
        </Backdrop>
    );
}
