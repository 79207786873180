import Color from "color";
import { Set as ImmutableSet } from "immutable";
import isEqual from "lodash/isEqual";
import * as React from "react";
import * as TorchlitCore from "../../core/Cargo.toml";
import { DoorData, DungeonData, DungeonTokenData } from "../store";
import "./dungeon.css";
import { ClientPoint, Point } from "./lib/geometry";
import { canControl, isGameMaster, isPlayer, isPointOfView } from "./lib/utils";

TorchlitCore.init();

interface TouchEvents {
    onTouchStart?: (this: GlobalEventHandlers, ev: TouchEvent) => any;
    onTouchMove?: (this: GlobalEventHandlers, ev: TouchEvent) => any;
    onTouchEnd?: (this: GlobalEventHandlers, ev: TouchEvent) => any;
    onTouchCancel?: (this: GlobalEventHandlers, ev: TouchEvent) => any;
}

interface DungeonCanvasProps {
    dungeon: DungeonData;
    doors: Record<string, DoorData>;
    tokens: Record<string, DungeonTokenData>;
    cursors: { x: number; y: number; color: string }[];
    player?: string | boolean;

    quadtree?: TorchlitCore.Quadtree | null;

    zoom: number;
    pan: Point;
    showGrid?: boolean;
    selection: ImmutableSet<string>;

    canvasProps?: React.HTMLAttributes<HTMLCanvasElement>;
    touchEvents?: TouchEvents;
}

interface DungeonCanvasState {
    imageUrl: string;
    backgroundUrl: string;
    loadedMap: boolean;
    loadedBackground: boolean;
    loadedTokens: {};
}

interface DungeonCanvasCache {
    doorSegments?: TorchlitCore.SegmentArray;
    visibilityPaths: Record<string, { token: DungeonTokenData; walls: TorchlitCore.SegmentArray; path: Float32Array }>;
    tokenImages: Record<string, HTMLImageElement>;
}

class DungeonCanvasChanges {
    public map: boolean = false;
    public background: boolean = false;
    public view: boolean = false;
    public grid: boolean = false;
    public ambientLight: boolean = false;
    public cursors: boolean = false;
    public selection: boolean = false;
    public doors: boolean = false;
    public loadedTokens: boolean = false;
    public movedTokens: Set<string> = new Set();
    public removedTokens: Set<string> = new Set();

    public clear() {
        this.map = false;
        this.background = false;
        this.view = false;
        this.grid = false;
        this.ambientLight = false;
        this.cursors = false;
        this.selection = false;
        this.doors = false;
        this.loadedTokens = false;
        this.movedTokens.clear();
        this.removedTokens.clear();
    }

    public any(): boolean {
        return (
            this.map ||
            this.background ||
            this.view ||
            this.grid ||
            this.ambientLight ||
            this.cursors ||
            this.selection ||
            this.doors ||
            this.loadedTokens ||
            this.movedTokens.size > 0 ||
            this.removedTokens.size > 0
        );
    }

    public accumulate(
        prevProps: DungeonCanvasProps,
        props: DungeonCanvasProps,
        prevState: DungeonCanvasState,
        state: DungeonCanvasState
    ) {
        this.map =
            this.map ||
            prevProps.quadtree !== props.quadtree ||
            prevState.imageUrl !== state.imageUrl ||
            prevState.loadedMap !== state.loadedMap;
        this.background =
            this.background ||
            prevState.backgroundUrl !== state.backgroundUrl ||
            prevState.loadedBackground !== state.loadedBackground;
        this.view = this.view || prevProps.zoom !== props.zoom || !isEqual(prevProps.pan, props.pan);
        this.grid =
            this.grid ||
            prevProps.showGrid !== props.showGrid ||
            prevProps.dungeon.gridColor !== props.dungeon.gridColor ||
            prevProps.dungeon.gridOffset !== props.dungeon.gridOffset ||
            prevProps.dungeon.gridScale !== props.dungeon.gridScale;
        this.ambientLight = this.ambientLight || prevProps.dungeon.ambientLight !== props.dungeon.ambientLight;
        this.cursors = this.cursors || !isEqual(prevProps.cursors, props.cursors);
        this.selection = this.selection || prevProps.selection.equals(props.selection);
        this.doors = this.doors || prevProps.doors !== props.doors;
        this.loadedTokens = this.loadedTokens || prevState.loadedTokens !== state.loadedTokens;

        if (prevProps.tokens !== props.tokens) {
            Object.entries(props.tokens)
                .filter(([k, v]) => !isEqual(prevProps.tokens[k]?.pos, v.pos))
                .forEach(([k, _]) => this.movedTokens.add(k));
            Object.keys(prevProps.tokens)
                .filter((k) => !props.tokens.hasOwnProperty(k))
                .forEach((k) => this.removedTokens.add(k));
        }
    }

    public init(props: DungeonCanvasProps, state: DungeonCanvasState) {
        this.map = props.quadtree != null && state.loadedMap;
        this.background = state.loadedBackground;
        this.view = true;
        this.grid = !!props.showGrid;
        this.ambientLight = props.dungeon.ambientLight != null && props.dungeon.ambientLight != "#000000";
        this.cursors = props.cursors.length > 0;
        this.selection = props.selection.size > 0;
        this.doors = Object.keys(props.doors).length > 0;
        this.loadedTokens = false;
        this.movedTokens = new Set(Object.keys(props.tokens));
        this.removedTokens.clear();
    }
}

export default class DungeonCanvas extends React.Component<DungeonCanvasProps, DungeonCanvasState> {
    public state: DungeonCanvasState = {
        loadedMap: false,
        loadedBackground: false,
        loadedTokens: {},
        imageUrl: "",
        backgroundUrl: "",
    };

    private canvasRef: React.RefObject<HTMLCanvasElement>;
    private cache: DungeonCanvasCache = {
        visibilityPaths: {},
        tokenImages: {},
    };
    private animationFrameRequest?: number;
    private changes = new DungeonCanvasChanges();
    private mapImage = new Image();
    private backgroundImage = new Image();

    constructor(props: DungeonCanvasProps) {
        super(props);
        this.canvasRef = React.createRef();
        this.handleResize = this.handleResize.bind(this);
    }

    public render() {
        const { canvasProps } = this.props;

        return <canvas ref={this.canvasRef} width={100} height={100} {...canvasProps} />;
    }

    static getDerivedStateFromProps(
        props: DungeonCanvasProps,
        state: DungeonCanvasState
    ): Partial<DungeonCanvasState> | null {
        const result: Partial<DungeonCanvasState> = {};

        if (props.dungeon.imageUrl !== state.imageUrl) {
            result.loadedMap = false;
            result.imageUrl = props.dungeon.imageUrl;
        }

        if (
            state.backgroundUrl !==
            "https://firebasestorage.googleapis.com/v0/b/torchlit-197721.appspot.com/o/background.jpg?alt=media&token=daba0948-b7bd-4e98-b31b-3644cae6468a"
        ) {
            result.loadedBackground = false;
            result.backgroundUrl =
                "https://firebasestorage.googleapis.com/v0/b/torchlit-197721.appspot.com/o/background.jpg?alt=media&token=daba0948-b7bd-4e98-b31b-3644cae6468a";
        }

        return result;
    }

    public componentDidMount() {
        this.changes.init(this.props, this.state);
        this.loadMap();
        this.loadBackground();
        this.updateTouchEventHandlers(undefined, this.props.touchEvents);
        window.addEventListener("resize", this.handleResize);

        this.requestAnimationFrame();
    }

    public componentDidUpdate(prevProps: Readonly<DungeonCanvasProps>, prevState: Readonly<DungeonCanvasState>) {
        if (this.state.imageUrl !== this.mapImage.src) {
            this.loadMap();
        }
        if (this.state.backgroundUrl !== this.backgroundImage.src) {
            this.loadBackground();
        }
        this.updateTouchEventHandlers(prevProps.touchEvents, this.props.touchEvents);
        this.changes.accumulate(prevProps, this.props, prevState, this.state);
        this.requestAnimationFrame();
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.mapImage.src = "";
        this.backgroundImage.src = "";
        this.updateTouchEventHandlers(this.props.touchEvents, undefined);

        if (this.animationFrameRequest != null) {
            cancelAnimationFrame(this.animationFrameRequest);
            this.animationFrameRequest = undefined;
        }

        this.clearCache();
    }

    public clientToPoint(p: ClientPoint): Point {
        const { pan, zoom } = this.props;
        const center = this.clientCenter();

        return {
            x: pan.x + (p.clientX - center.clientX) / zoom,
            y: pan.y + (p.clientY - center.clientY) / zoom,
        };
    }

    private clientCenter(): ClientPoint {
        const canvas = this.canvasRef.current;
        if (canvas != null) {
            const rect = canvas.getBoundingClientRect();
            return {
                clientX: (rect.left + rect.right) / 2,
                clientY: (rect.top + rect.bottom) / 2,
            };
        } else {
            return {
                clientX: 0,
                clientY: 0,
            };
        }
    }

    private updateTouchEventHandlers(prev: TouchEvents | undefined, next: TouchEvents | undefined) {
        const canvas = this.canvasRef.current;
        if (canvas == null) {
            return;
        }

        const keys: [keyof TouchEvents, "touchstart" | "touchmove" | "touchend" | "touchcancel"][] = [
            ["onTouchStart", "touchstart"],
            ["onTouchMove", "touchmove"],
            ["onTouchEnd", "touchend"],
            ["onTouchCancel", "touchcancel"],
        ];
        for (const [key, event] of keys) {
            const p = prev?.[key];
            const n = next?.[key];

            if (p) {
                canvas.removeEventListener(event, p);
            }
            if (n) {
                canvas.addEventListener(event, n);
            }
        }
    }

    private loadMap() {
        const url = this.state.imageUrl;
        this.mapImage.src = url;
        this.mapImage.decode().then(() => {
            if (this.mapImage.src === url) {
                this.setState({ loadedMap: true });
            }
        });
    }

    private loadBackground() {
        const url = this.state.backgroundUrl;
        this.backgroundImage.src = url;
        this.backgroundImage.decode().then(() => {
            if (this.backgroundImage.src === url) {
                this.setState({ loadedBackground: true });
            }
        });
    }

    private requestAnimationFrame() {
        if (this.animationFrameRequest == null && this.readyToRender() && this.changes.any()) {
            this.animationFrameRequest = requestAnimationFrame(() => {
                this.animationFrameRequest = undefined;
                if (this.readyToRender()) {
                    this.updateCanvas();
                    this.changes.clear();
                }
            });
        }
    }

    private readyToRender(): boolean {
        return (
            this.canvasRef.current != null && !!this.mapImage.src && this.state.loadedMap && this.props.quadtree != null
        );
    }

    private handleResize() {
        this.changes.view = true;
        this.requestAnimationFrame();
    }

    private clearCache() {
        if (this.cache.doorSegments != null) {
            this.cache.doorSegments.free();
            this.cache.doorSegments = undefined;
        }

        for (const path of Object.values(this.cache.visibilityPaths)) {
            path.walls.free();
        }
        this.cache.visibilityPaths = {};

        this.cache.tokenImages = {};
    }

    private updateCache() {
        const { doors, tokens, quadtree } = this.props;
        const mapImage = this.mapImage;

        if (quadtree == null || mapImage == null) {
            throw new Error("updateCache called before readyToRender");
        }

        const { width, height } = mapImage;
        const cache = this.cache;

        // Update door segments
        if (this.changes.doors) {
            if (cache.doorSegments != null) {
                cache.doorSegments.free();
            }

            if (doors != null) {
                cache.doorSegments = quadtree!.door_segments(Object.values(doors).filter((d) => !d.open));
            }
        }

        if (cache.doorSegments == null) {
            cache.doorSegments = new TorchlitCore.SegmentArray();
        }

        // Remove stale visibility paths
        for (const key of this.changes.map ? Object.keys(cache.visibilityPaths) : this.changes.removedTokens) {
            const visibility = cache.visibilityPaths[key];
            if (visibility != null) {
                visibility.walls.free();
                delete cache.visibilityPaths[key];
            }
        }

        // Calculate changed visibility paths
        const needsVisibility = (token: DungeonTokenData) =>
            token.emitsLight != null || isPointOfView(token, this.props.player);

        // Update visible walls for moved tokens
        const bounds = { x: 0, y: 0, w: width, h: height };
        for (const key of this.changes.movedTokens) {
            const token = this.props.tokens[key];
            if (token == null || !needsVisibility(token)) {
                continue;
            }

            cache.visibilityPaths[key]?.walls?.free();
            const walls = quadtree.wall_segments(token.pos, bounds);
            cache.visibilityPaths[key] = { token, walls } as any;
        }

        // Update visibility path for either moved tokens or all tokens if doors changed
        if (this.changes.map || this.changes.doors || this.changes.movedTokens.size > 0) {
            for (const [key, token] of Object.entries(tokens).filter(([_, t]) => needsVisibility(t))) {
                let visibility = cache.visibilityPaths[key];
                if (visibility == null) {
                    const walls = quadtree.wall_segments(token.pos, bounds);
                    visibility = cache.visibilityPaths[key] = { token, walls } as any;
                }

                if (visibility.path == null || this.changes.doors) {
                    visibility.path = quadtree.visible_region(token.pos, bounds, visibility.walls, cache.doorSegments);
                }
            }
        }
    }

    private updateCanvas() {
        const canvas = this.canvasRef.current;
        const img = this.mapImage;

        if (canvas == null || !img.src || !img.complete) {
            throw new Error("updateCache called before readyToRender");
        }

        const width = canvas.scrollWidth;
        const height = canvas.scrollHeight;
        if (width != canvas.width) {
            canvas.width = width;
        }
        if (height != canvas.height) {
            canvas.height = height;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) {
            throw new Error("failed to get canvas context");
        }

        // Update cache
        this.updateCache();

        const {
            dungeon: { gridScale, gridOffset, gridColor, ambientLight },
            tokens,
            doors,
            cursors,
            pan,
            zoom,
            showGrid,
            player,
        } = this.props;
        const { visibilityPaths } = this.cache;

        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.fillStyle = ambientLight || "#000000";
        ctx.fillRect(0, 0, width, height);

        ctx.scale(zoom, zoom);
        ctx.translate(-pan.x + width / zoom / 2, -pan.y + height / zoom / 2);

        if (ambientLight !== "#ffffff") {
            // Draw light sources
            ctx.globalCompositeOperation = "lighten"; // Use the brightest light reaching any point
            for (const visibility of Object.values(visibilityPaths).filter((v) => v.token.emitsLight != null)) {
                const token = visibility.token;
                const pos = token.pos;
                const dimRadius = token.emitsLight!.dim * gridScale;
                const brightRadius = Math.min(token.emitsLight!.bright * gridScale, dimRadius);
                const gradient = ctx.createRadialGradient(pos.x, pos.y, 0, pos.x, pos.y, dimRadius);
                const color = Color(token.emitsLight!.color || "#ffffff");
                if (brightRadius > 0) {
                    gradient.addColorStop(0, color.string());
                    gradient.addColorStop(brightRadius / dimRadius - 0.01, color.string());
                    gradient.addColorStop(brightRadius / dimRadius + 0.01, color.darken(0.5).desaturate(0.5).string());
                } else {
                    gradient.addColorStop(0, color.darken(0.5).desaturate(0.5).string());
                }
                gradient.addColorStop(0.95, color.darken(0.5).desaturate(0.5).string());
                gradient.addColorStop(1, "#000000");
                ctx.fillStyle = gradient;
                ctx.beginPath();
                drawPath(ctx, visibility.path);
                ctx.fill();
            }

            // Add darkvision
            ctx.globalCompositeOperation = "lighter"; // Darkvision adds to light
            for (const visibility of Object.values(visibilityPaths).filter(
                (v) => isPointOfView(v.token, player) && v.token.darkvision != null
            )) {
                const token = visibility.token;
                const pos = token.pos;
                const radius = token.darkvision! * gridScale;
                const gradient = ctx.createRadialGradient(pos.x, pos.y, 0, pos.x, pos.y, radius);
                gradient.addColorStop(0, "rgba(255,128,128,0.5)");
                gradient.addColorStop(0.95, "rgba(255,128,128,0.5)");
                gradient.addColorStop(1, "rgba(255,128,128,0)");
                ctx.fillStyle = gradient;
                ctx.beginPath();
                drawPath(ctx, visibility.path);
                ctx.fill();
            }

            if (isGameMaster(player)) {
                // GM has global darkvision
                ctx.globalCompositeOperation = "lighten";
                ctx.fillStyle = "rgba(255,255,255,0.5)";
                const { min, max } = this.canvasRect();
                ctx.fillRect(min.x, min.y, max.x - min.x, max.y - min.y);
            }
        }

        // Draw the map
        ctx.globalCompositeOperation = "multiply";
        ctx.drawImage(img, 0, 0);

        // Draw doors
        if (isPlayer(player)) {
            ctx.globalCompositeOperation = "destination-out";
            for (const door of Object.values(doors)) {
                drawDoor(ctx, door, true, false);
            }
        }

        // Draw tokens in line of sight
        ctx.globalCompositeOperation = "source-over";
        if (isPlayer(player)) {
            for (const token of Object.values(tokens).filter(
                (t) => !isPointOfView(t, player) && (!t.invisible || canControl(t, player))
            )) {
                this.drawToken(ctx, token);
            }
        }

        // Mask by the visibility path
        if (isPlayer(player)) {
            ctx.globalCompositeOperation = "destination-in";
            ctx.fillStyle = "black";
            let drewPath = false;
            ctx.beginPath();
            for (const visibility of Object.values(visibilityPaths).filter((p) => isPointOfView(p.token, player))) {
                const path = visibility.path;
                drawPath(ctx, path);
                drewPath = drewPath || path.length > 0;
            }
            if (drewPath) {
                ctx.fill();
            } else {
                ctx.fillRect(0, 0, 0, 0);
            }
        } else {
            ctx.globalCompositeOperation = "destination-in";
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, img.naturalWidth, img.naturalHeight);
        }

        if (this.backgroundImage != null) {
            const pattern = ctx.createPattern(this.backgroundImage, "repeat");
            if (pattern != null) {
                ctx.globalCompositeOperation = "destination-over";
                ctx.fillStyle = pattern;
                const { min, max } = this.canvasRect();
                ctx.fillRect(min.x, min.y, max.x - min.x, max.y - min.y);
            }
        }

        // Draw doors
        ctx.globalCompositeOperation = "source-over";
        if (isGameMaster(player)) {
            for (const [key, door] of Object.entries(doors)) {
                drawDoor(ctx, door, false, this.props.selection.has(key));
            }
        }

        // Draw always-visible tokens
        for (const token of Object.values(tokens).filter((t) => isGameMaster(player) || isPointOfView(t, player))) {
            this.drawToken(ctx, token);
        }

        // DEBUGGING
        // ctx.lineWidth = 0.05;
        // for (const visibility of Object.values(visibilityPaths)) {
        //     const arr = visibility.walls.to_raw();
        //     for (let i = 0; i < arr.length; i += 4) {
        //         ctx.strokeStyle = ["red", "green", "blue", "yellow", "cyan", "magenta"][Math.floor(Math.random() * 6)];
        //         ctx.beginPath();
        //         ctx.moveTo(arr[i], arr[i + 1]);
        //         ctx.lineTo(arr[i + 2], arr[i + 3]);
        //         ctx.stroke();
        //     }
        // }

        // Draw the grid
        if (gridColor != null || showGrid) {
            ctx.strokeStyle = gridColor || "#90caf9";
            ctx.lineWidth = gridScale / 30;
            ctx.beginPath();
            const { min, max } = this.canvasRect();
            const step = gridScale;
            const xOffset = (gridOffset?.x || 0.0) - 0.5 * step;
            const yOffset = (gridOffset?.y || 0.0) - 0.5 * step;
            for (let i = 0; i <= Math.ceil((max.x - min.x) / step); i++) {
                ctx.moveTo(min.x - (min.x % step) + xOffset + i * step, min.y);
                ctx.lineTo(min.x - (min.x % step) + xOffset + i * step, max.y);
            }
            for (let i = 0; i <= Math.ceil((max.y - min.y) / step); i++) {
                ctx.moveTo(min.x, min.y - (min.y % step) + yOffset + i * step);
                ctx.lineTo(max.x, min.y - (min.y % step) + yOffset + i * step);
            }
            ctx.stroke();
        }

        // Draw cursors
        for (const cursor of cursors) {
            ctx.strokeStyle = "black";
            ctx.fillStyle = cursor.color;
            ctx.beginPath();
            ctx.arc(cursor.x, cursor.y, 5 / zoom, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        }

        ctx.restore();
    }

    private canvasRect(): { min: Point; max: Point } {
        const { pan, zoom } = this.props;
        const { width, height } = this.canvasRef.current!;
        const min = { x: pan.x - width / zoom / 2, y: pan.y - height / zoom / 2 };
        const max = { x: min.x + width / zoom, y: min.y + height / zoom };
        return { min, max };
    }

    private drawToken(ctx: CanvasRenderingContext2D, token: DungeonTokenData) {
        ctx.globalAlpha = token.invisible ? 0.5 : 1.0;
        let img = this.cache.tokenImages[token.imageUrl];
        if (img == null) {
            img = new Image();
            img.src = token.imageUrl;
            img.decode().then(() => this.setState({ loadedTokens: {} }));
            this.cache.tokenImages[token.imageUrl] = img;
        } else if (img.complete) {
            const gridScale = this.props.dungeon.gridScale;
            let w = token.size.x * (gridScale - 4);
            let h = token.size.y * (gridScale - 4);
            if (img.naturalWidth / w > img.naturalHeight / h) {
                h = (w / img.naturalWidth) * img.naturalHeight;
            } else {
                w = (h / img.naturalHeight) * img.naturalWidth;
            }

            ctx.drawImage(img, token.pos.x - w / 2, token.pos.y - h / 2, w, h);

            if (isGameMaster(this.props.player) || !!token.player) {
                // if (token.health != null) {
                //     const { current, max } = token.health;
                //     const barInset = 0.075;
                //     const barWidth = ((token.size.x - 2 * barInset) * gridScale * current) / max;
                //     const barLeft = token.pos.x - (token.size.x * 0.5 - barInset) * gridScale;
                //     const barTop = token.pos.y - (token.size.y * 0.5 - barInset) * gridScale;

                //     ctx.strokeStyle =
                //         current > max
                //             ? "#2196f3"
                //             : current > 0.5 * max
                //             ? "#4caf50"
                //             : current > 0.25 * max
                //             ? "#ffc107"
                //             : "#f44336";
                //     ctx.lineWidth = gridScale * 0.05;
                //     ctx.beginPath();
                //     ctx.moveTo(barLeft, barTop);
                //     ctx.lineTo(barLeft + barWidth, barTop);
                //     ctx.stroke();

                //     ctx.font = `${gridScale / 4}px "Cinzel Regular", serif`;

                //     const maxNameWidth = token.size.x * gridScale - 4;
                //     const nameWidth = ctx.measureText(token.name).width;
                //     if (nameWidth > maxNameWidth) {
                //         ctx.font = `${Math.max(
                //             (gridScale / 4) * (maxNameWidth / nameWidth),
                //             gridScale / 8
                //         )}px "Cinzel Regular", serif`;
                //     }
                // }

                ctx.textAlign = "center";
                ctx.textBaseline = "alphabetic";
                ctx.fillStyle = "white";
                ctx.strokeStyle = "black";
                ctx.lineWidth = 3;
                ctx.lineJoin = "round";

                ctx.strokeText(
                    token.name,
                    token.pos.x,
                    token.pos.y + (token.size.y * gridScale) / 2,
                    token.size.x * gridScale - 4
                );
                ctx.fillText(
                    token.name,
                    token.pos.x,
                    token.pos.y + (token.size.y * gridScale) / 2,
                    token.size.x * gridScale - 4
                );
            }
        }
        ctx.globalAlpha = 1.0;
    }
}

function drawPath(ctx: CanvasRenderingContext2D, path: Float32Array) {
    if (path.length > 0) {
        ctx.moveTo(path[0], path[1]);
        for (let i = 2; i < path.length; i += 2) {
            ctx.lineTo(path[i], path[i + 1]);
        }
    }
}

function drawDoor(ctx: CanvasRenderingContext2D, door: DoorData, player: boolean, selected: boolean) {
    if (player) {
        if (!door.open) {
            drawDoorInset(ctx, door, false, 0, "black");
        }
    } else {
        const outlineColor = selected ? "#ef6c00" : "black";
        drawDoorInset(ctx, door, true, 0, outlineColor);
        drawDoorInset(ctx, door, true, 2.5, "#4caf50");
    }
}

function drawDoorInset(
    ctx: CanvasRenderingContext2D,
    door: DoorData,
    endpoints: boolean,
    inset: number,
    color: string
) {
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.lineWidth = 5 - inset;
    if (door.open) {
        ctx.setLineDash([15 - inset, 5 + inset]);
        ctx.lineDashOffset = -inset / 2;
    }
    ctx.beginPath();
    ctx.moveTo(door.from.x, door.from.y);
    ctx.lineTo(door.to.x, door.to.y);
    ctx.stroke();
    ctx.setLineDash([]);

    if (endpoints) {
        ctx.beginPath();
        ctx.arc(door.from.x, door.from.y, 5 - 0.5 * inset, 0, 2 * Math.PI);
        ctx.arc(door.to.x, door.to.y, 5 - 0.5 * inset, 0, 2 * Math.PI);
        ctx.fill();
    }
}
