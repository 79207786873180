import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../actions";
import GameList from "./GameList";

const useStyles = makeStyles(() =>
    createStyles({
        item: {
            display: "flex",
            justifyContent: "space-around",
        },
    })
);

export default function Home() {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Container classes={{ root: "Home" }}>
            <Grid container spacing={5} direction="column">
                <Grid item className={classes.item}>
                    <Button variant="contained" color="primary" onClick={() => dispatch(showModal("CREATE_GAME", {}))}>
                        Create a game
                    </Button>
                </Grid>
                <Grid item className={classes.item}>
                    <GameList />
                </Grid>
            </Grid>
        </Container>
    );
}
