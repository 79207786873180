import { makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { useFirebase, useFirebaseConnect } from "react-redux-firebase";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Dungeon } from "../dungeon";
import { MemberData, RootState } from "../store";
import GameDrawer from "./GameDrawer";
import Loading from "./Loading";

const { useEffect } = React;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        position: "relative",
    },
}));

function DungeonLoader({
    gameId,
    dungeonId,
    uid,
    player,
    members,
}: {
    gameId: string;
    dungeonId: string;
    uid: string;
    player?: string | boolean;
    members: Record<string, MemberData>;
}): JSX.Element | null {
    const firebase = useFirebase();
    useFirebaseConnect([`dungeons/${dungeonId}`, `doors/${dungeonId}`, `tokens/${dungeonId}`]);

    const { dungeon, doors, tokens } = useSelector(({ firebase: { data } }: RootState) => ({
        dungeon: data.dungeons?.[dungeonId],
        doors: data.doors?.[dungeonId],
        tokens: data.tokens?.[dungeonId],
    }));

    if (dungeon == null) {
        return <Loading open />;
    } else {
        return (
            <Dungeon
                firebase={firebase}
                gameId={gameId}
                dungeonId={dungeonId}
                uid={uid}
                dungeon={dungeon}
                members={members}
                doors={doors || {}}
                tokens={tokens || {}}
                player={player}
            />
        );
    }
}

function AuthorizedGameLoader({ gameId, members }: { gameId: string; members: Record<string, MemberData> }) {
    const match = useRouteMatch();
    useFirebaseConnect([`games/${gameId}`]);
    const classes = useStyles();

    const { auth, game } = useSelector(({ firebase: { auth, data, profile } }: RootState) => ({
        auth,
        profile,
        game: data.games?.[gameId],
    }));

    if (game == null) {
        return <Loading open />;
    } else {
        return (
            <div className={classes.root}>
                {game.owner === auth.uid ? (
                    <Switch>
                        <Route
                            path={`${match.path}/d/:dungeonId`}
                            render={({ match }) => (
                                <DungeonLoader
                                    gameId={gameId}
                                    dungeonId={match.params.dungeonId}
                                    uid={auth.uid}
                                    members={members}
                                />
                            )}
                        />
                        <Route path={`${match.path}/p`}>
                            <DungeonLoader
                                gameId={gameId}
                                dungeonId={game.activeDungeon}
                                uid={auth.uid}
                                player
                                members={members}
                            />
                        </Route>
                        <Route path={match.path}>
                            <DungeonLoader
                                gameId={gameId}
                                dungeonId={game.activeDungeon}
                                uid={auth.uid}
                                members={members}
                            />
                        </Route>
                    </Switch>
                ) : (
                    <DungeonLoader
                        gameId={gameId}
                        dungeonId={game.activeDungeon}
                        uid={auth.uid}
                        player={auth.uid}
                        members={members}
                    />
                )}
                <GameDrawer gameId={gameId} game={game} uid={auth.uid} members={members} />
            </div>
        );
    }
}

export default function GameLoader({ gameId }: { gameId: string }): JSX.Element {
    const firebase = useFirebase();
    useFirebaseConnect(`members/${gameId}`);

    const { auth, profile, members } = useSelector(({ firebase: { auth, data, profile } }: RootState) => ({
        auth,
        profile,
        members: data.members?.[gameId],
    }));

    // TODO: Add security here
    useEffect(() => {
        if (members && !(auth.uid in members)) {
            firebase.set(`/members/${gameId}/${auth.uid}`, {
                displayName: profile.displayName,
                avatarUrl: profile.avatarUrl,
                color: profile.preferredColor,
            });
            firebase.set(`/users/${auth.uid}/games/${gameId}`, false);
        }
    }, [auth.uid, members]);

    if (members == null || !(auth.uid in members)) {
        return <Loading open />;
    } else {
        return <AuthorizedGameLoader gameId={gameId} members={members} />;
    }
}
