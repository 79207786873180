import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Loading({ open }: { open: boolean }): JSX.Element {
    return (
        <Backdrop open={open}>
            <CircularProgress />
        </Backdrop>
    );
}
