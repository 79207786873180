import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import { useDispatch } from "react-redux";
import { createDungeon, showAlert } from "../actions";
import DungeonFields, { DungeonFieldValues } from "./DungeonFields";

const { useState } = React;

export interface CreateDungeonDialogProps extends Omit<DialogProps, "children"> {
    gameId: string;
    onClose?: (ev: {}, reason: "backdropClick" | "escapeKeyDown" | "cancelClick") => void;
}

export default function CreateDungeonDialog({
    gameId,
    open,
    onClose,
    ...props
}: CreateDungeonDialogProps): JSX.Element {
    const dispatch = useDispatch();

    const [fields, setFields] = useState(new DungeonFieldValues());

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (gameId != null && DungeonFieldValues.isValid(fields) && fields.image != null) {
            dispatch(createDungeon(gameId, fields.name, fields.image, fields.visibility));
        } else {
            dispatch(showAlert("error", "Attempt to create dungeon with invalid data"));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} {...props}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>New Dungeon</DialogTitle>
                <DialogContent>
                    <DungeonFields fields={fields} onChange={setFields} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(ev) => onClose && onClose(ev, "cancelClick")} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={!DungeonFieldValues.isValid(fields)}>
                        Create map
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
