import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, removeModal } from "../actions";
import { ActiveModalState, RootState } from "../store";
import CreateDungeonDialog from "./CreateDungeonDialog";
import CreateGameDialog from "./CreateGameDialog";
import CreateTokenDialog from "./CreateTokenDialog";

export * from "./CreateDungeonDialog";
export * from "./CreateGameDialog";
export * from "./CreateTokenDialog";

export default function Modals(): JSX.Element | null {
    const modal = useSelector((state: RootState) => state.modal);
    const dispatch = useDispatch();

    switch (modal.type) {
        case "CREATE_DUNGEON":
            return (
                <CreateDungeonDialog
                    open={modal.open}
                    onClose={() => dispatch(hideModal())}
                    onExited={() => dispatch(removeModal())}
                    {...(modal as ActiveModalState<"CREATE_DUNGEON">).props}
                />
            );
        case "CREATE_GAME":
            return (
                <CreateGameDialog
                    open={modal.open}
                    onClose={() => dispatch(hideModal())}
                    onExited={() => dispatch(removeModal())}
                    {...(modal as ActiveModalState<"CREATE_GAME">).props}
                />
            );
        case "CREATE_TOKEN":
            return (
                <CreateTokenDialog
                    open={modal.open}
                    onClose={() => dispatch(hideModal())}
                    onExited={() => dispatch(removeModal())}
                    {...(modal as ActiveModalState<"CREATE_TOKEN">).props}
                />
            );
        default:
            return null;
    }
}
