import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useSelector } from "react-redux";
import { hideAlert, removeAlert } from "../actions";
import { RootState } from "../store";

export default function Alerts(): JSX.Element | null {
    const alerts = useSelector((state: RootState) => state.alerts);

    if (alerts.length === 0) {
        return null;
    } else {
        return (
            <>
                {alerts.map((x) => (
                    <Snackbar
                        key={x.key}
                        open={x.open}
                        autoHideDuration={6000}
                        onClose={() => hideAlert(x.key)}
                        onExited={() => removeAlert(x.key)}
                    >
                        <Alert onClose={() => hideAlert(x.key)} severity={x.severity}>
                            {x.message}
                        </Alert>
                    </Snackbar>
                ))}
            </>
        );
    }
}
