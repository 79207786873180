import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Group from "@material-ui/icons/Group";
import MapOutlined from "@material-ui/icons/MapOutlined";
import React, { useState } from "react";
import { GameData, MemberData } from "../store";
import DungeonsList from "./DungeonsList";
import MembersList from "./MembersList";
import TokensList from "./TokensList";

const mdiChessPawn =
    "M19 22H5V20H19V22M16 18H8L10.18 10H8V8H10.72L10.79 7.74C10.1 7.44 9.55 6.89 9.25 6.2C8.58 4.68 9.27 2.91 10.79 2.25C12.31 1.58 14.08 2.27 14.74 3.79C15.41 5.31 14.72 7.07 13.2 7.74L13.27 8H16V10H13.82L16 18Z";

const drawerWidth = "30rem";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "absolute",
        right: 0,
        height: "100%",
        pointerEvents: "none",
    },
    paper: {
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
        // backgroundColor: fade(theme.palette.background.paper, 0.9),
        // WebkitBackdropFilter: "blur(50px)",
        // backdropFilter: "blur(50px)",
    },
    open: {
        width: drawerWidth,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        width: theme.spacing(7) + 1,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerContent: {
        display: "flex",
        width: `calc(${drawerWidth} - 1px)`,
        height: "100%",
        overflowX: "hidden",
        alignItems: "start",
    },
    tabs: { flexShrink: 0 },
    tab: { minWidth: theme.spacing(7) },
    tabsContainer: {
        background: theme.palette.background.default,
        borderRadius: `${2 * theme.shape.borderRadius}px 0 0 ${2 * theme.shape.borderRadius}px`,
        pointerEvents: "all",
    },
    tabPanel: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        backgroundColor: theme.palette.background.default,
        pointerEvents: "all",
    },
}));

export default function GameDrawer({
    gameId,
    game,
    uid,
    members,
}: {
    gameId: string;
    game: GameData;
    uid: string;
    members: Record<string, MemberData>;
}): JSX.Element | null {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);

    const gameOwner = game.owner == uid;

    return (
        <Drawer
            className={[classes.root, open ? classes.open : classes.close].join(" ")}
            variant="permanent"
            anchor="right"
            classes={{ paper: [classes.paper, open ? classes.open : classes.close].join(" ") }}
        >
            <div className={classes.drawerContent}>
                <div className={classes.tabsContainer}>
                    <Tabs
                        orientation="vertical"
                        variant="standard"
                        value={tab}
                        className={classes.tabs}
                        onChange={(_, newValue) => {
                            setOpen(true);
                            setTab(newValue);
                        }}
                        indicatorColor="primary"
                    >
                        <Tab icon={<Group />} classes={{ root: classes.tab }} />
                        {gameOwner && <Tab icon={<MapOutlined />} classes={{ root: classes.tab }} />}
                        {gameOwner && (
                            <Tab
                                icon={
                                    <SvgIcon>
                                        <path d={mdiChessPawn} />
                                    </SvgIcon>
                                }
                                classes={{ root: classes.tab }}
                            />
                        )}
                    </Tabs>
                    <IconButton className={classes.tab} onClick={() => setOpen(!open)}>
                        {open ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </div>
                <div className={classes.tabPanel} role="tabpanel" hidden={tab !== 0}>
                    {tab === 0 && <MembersList gameOwner={gameOwner} uid={uid} members={members} />}
                </div>
                {gameOwner && (
                    <div className={classes.tabPanel} role="tabpanel" hidden={tab !== 1}>
                        {tab === 1 && <DungeonsList gameId={gameId} game={game} />}
                    </div>
                )}
                {gameOwner && (
                    <div className={classes.tabPanel} role="tabpanel" hidden={tab !== 2}>
                        {tab === 2 && <TokensList uid={uid} />}
                    </div>
                )}
            </div>
        </Drawer>
    );
}
