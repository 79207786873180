import CircularProgress from "@material-ui/core/CircularProgress";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useSelector } from "react-redux";
import { useFirebase, useFirebaseConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { GameData, RootState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: 758,
        },
    })
);

function GameThumbnail(props: { gameId: string }): JSX.Element {
    useFirebaseConnect(`games/${props.gameId}`);
    const game = useSelector(
        ({
            firebase: {
                data: { games },
            },
        }: RootState) => games?.[props.gameId]
    );

    if (game == null) {
        return <CircularProgress />;
    } else {
        return <GameThumbnailWithGame game={game} />;
    }
}

function GameThumbnailWithGame({ game }: { game: GameData }): JSX.Element | null {
    const firebase = useFirebase();
    useFirebaseConnect(`users/${game.owner}/displayName`);
    const owner = useSelector(
        ({
            firebase: {
                data: { users },
            },
        }: RootState) => users?.[game.owner]
    );

    if (game == null) {
        return null;
    } else {
        return (
            <>
                <img src={game.thumbnailUrl} alt={game.name} />
                <GridListTileBar
                    title={game.name}
                    subtitle={`run by ${owner != null ? owner.displayName : "Unknown"}`}
                    actionIcon={
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    }
                />
            </>
        );
    }
}

export default function GameList(): JSX.Element | null {
    const { profile } = useSelector(({ firebase: { profile } }: RootState) => ({ profile }));
    const classes = useStyles();

    if (profile.games != null) {
        return (
            <div className={classes.root}>
                <GridList cellHeight={250} className={classes.gridList} cols={3}>
                    {Object.keys(profile.games).map((gameId) => (
                        <GridListTile key={gameId} cols={1}>
                            <Link to={`/games/${gameId}`}>
                                <GameThumbnail gameId={gameId} />
                            </Link>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    } else {
        return null;
    }
}
