export interface ClientPoint {
    clientX: number;
    clientY: number;
}

export class Point {
    public static difference(a: Point, b: Point): Point {
        return new Point(a.x - b.x, a.y - b.y);
    }

    public static offset(a: Point, b: Point): Point {
        return new Point(a.x + b.x, a.y + b.y);
    }

    public static interpolate(p: Point, q: Point, f: number): Point {
        return { x: p.x * (1 - f) + q.x * f, y: p.y * (1 - f) + q.y * f };
    }

    public static sqrMagnitude(p: Point) {
        return p.x * p.x + p.y * p.y;
    }

    public static magnitude(p: Point) {
        return Math.sqrt(Point.sqrMagnitude(p));
    }

    public static sqrDistance(a: Point, b: Point) {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return dx * dx + dy * dy;
    }

    public static distance(a: Point, b: Point) {
        return Math.sqrt(Point.sqrDistance(a, b));
    }

    public static scale(a: Point, by: number) {
        return new Point(a.x * by, a.y * by);
    }

    public static normalize(a: Point, scale: number = 1) {
        return Point.scale(a, scale / Point.magnitude(a));
    }

    // Returns a value representing the quadrant of P:
    //   Quadrant I: 1
    //   Quadrant II: 2
    //   Quadrant III: -2
    //   Quadrant IV: -1
    // Points on the Y-axis are considered part of quadrants I/IV.
    // Points on the X-axis are considered part of quadrants I/II.
    public static quadrant(p: Point) {
        const sy = Math.sign(p.y) || 1;
        return 1.5 * sy - 0.5 * sy * (Math.sign(p.x) || 1);
    }

    public static compareSlope(a: Point, b: Point) {
        const dq = Point.quadrant(a) - Point.quadrant(b);
        if (dq) {
            return dq;
        } else {
            const p = a.x * b.y;
            const q = a.y * b.x;
            return Math.sign(q - p);
        }
    }

    public static compareSlopeFrom(a: Point, b: Point, origin: Point) {
        // Inlined for optimization
        const ax = a.x - origin.x;
        const ay = a.y - origin.y;
        const bx = b.x - origin.x;
        const by = b.y - origin.y;

        let sy = Math.sign(ay) || 1;
        const qa = 1.5 * sy - 0.5 * sy * (Math.sign(ax) || 1);
        sy = Math.sign(by) || 1;
        const qb = 1.5 * sy - 0.5 * sy * (Math.sign(bx) || 1);
        const dq = qa - qb;

        if (dq) {
            return dq;
        } else {
            const p = ax * by;
            const q = ay * bx;
            return Math.sign(q - p);
        }
    }

    constructor(public x: number, public y: number) {}
}

export class Segment {
    public static leftOf(s: Segment, p: Point) {
        const cross = (s.to.x - s.from.x) * (p.y - s.from.y) - (s.to.y - s.from.y) * (p.x - s.from.x);
        return cross > 0;
    }

    public static intersects(a: Segment, b: Segment) {
        const [ta, tb] = Segment.intersection(a, b);
        return ta >= 0 && ta <= 1 && tb >= 0 && tb <= 1;
    }

    public static compareDistance(a: Segment, b: Segment, origin: Point) {
        // Inlined for optimization
        let x0 = a.from.x;
        let y0 = a.from.y;
        let dx = a.to.x - x0;
        let dy = a.to.y - y0;

        // Move the endpoint we're testing in slightly so endpoint
        // intersections don't count.
        let px = b.from.x * 0.99 + b.to.x * 0.01;
        let py = b.from.y * 0.99 + b.to.y * 0.01;
        const bFromLeftOfA = dx * (py - y0) - dy * (px - x0) > 0;

        px = b.to.x * 0.99 + b.from.x * 0.01;
        py = b.to.y * 0.99 + b.from.y * 0.01;
        const bToLeftOfA = dx * (py - y0) - dy * (px - x0) > 0;

        if (bFromLeftOfA === bToLeftOfA) {
            const originLeftOfA = dx * (origin.y - y0) - dy * (origin.x - x0) > 0;
            return bToLeftOfA === originLeftOfA ? 1 : -1;
        }

        x0 = b.from.x;
        y0 = b.from.y;
        dx = b.to.x - x0;
        dy = b.to.y - y0;

        px = a.from.x * 0.99 + a.to.x * 0.01;
        py = a.from.y * 0.99 + a.to.y * 0.01;
        const aFromLeftOfB = dx * (py - y0) - dy * (px - x0) > 0;

        px = a.to.x * 0.99 + a.from.x * 0.01;
        py = a.to.y * 0.99 + a.from.y * 0.01;
        const aToLeftOfB = dx * (py - y0) - dy * (px - x0) > 0;

        if (aFromLeftOfB === aToLeftOfB) {
            const originLeftOfB = dx * (origin.y - y0) - dy * (origin.x - x0) > 0;
            return aToLeftOfB === originLeftOfB ? -1 : 1;
        }

        return 0;
    }

    public static intersection(a: Segment, b: Segment) {
        const dxa = a.to.x - a.from.x;
        const dya = a.to.y - a.from.y;
        const dxb = b.to.x - b.from.x;
        const dyb = b.to.y - b.from.y;
        const d = dxa * dyb - dya * dxb;
        const ta = ((a.from.y - b.from.y) * dxb - (a.from.x - b.from.x) * dyb) / d;
        const tb = (a.from.x - b.from.x + ta * dxa) / dxb;
        return [ta, tb];
    }

    public static intersectionPoint(a: Segment, b: Segment) {
        const {
            from: { x: x1, y: y1 },
            to: { x: x2, y: y2 },
        } = a;
        const {
            from: { x: x3, y: y3 },
            to: { x: x4, y: y4 },
        } = b;

        const x12 = x1 - x2;
        const x34 = x3 - x4;
        const y12 = y1 - y2;
        const y34 = y3 - y4;

        const p = x1 * y2 - y1 * x2;
        const q = x3 * y4 - y3 * x4;
        const d = x12 * y34 - y12 * x34;

        const x = (p * x34 - q * x12) / d;
        const y = (p * y34 - q * y12) / d;

        return { x, y };
    }

    public static interpolate(a: Segment, f: number) {
        return Point.interpolate(a.from, a.to, f);
    }

    public static size(a: Segment) {
        return Point.distance(a.from, a.to);
    }

    public static distance(a: Segment, p: Point) {
        const q = a.to.x * a.from.y - a.from.x * a.to.y;
        return ((a.to.y - a.from.y) * p.x - (a.to.x - a.from.x) * p.y + q) / Segment.size(a);
    }

    public static offset(a: Segment, by: Point) {
        return new Segment(Point.offset(a.from, by), Point.offset(a.to, by));
    }

    public static bounds(a: Segment) {
        return new Box(
            Math.min(a.from.x, a.to.x),
            Math.min(a.from.y, a.to.y),
            Math.abs(a.to.x - a.from.x),
            Math.abs(a.to.y - a.from.y)
        );
    }

    constructor(public from: Point, public to: Point) {}
}

export class Box {
    public static intersect(b1: Box, b2: Box) {
        return b1.x + b1.w >= b2.x && b2.x + b2.w >= b1.x && b1.y + b1.h >= b2.y && b2.y + b2.h >= b1.y;
    }

    public static contains(b: Box, p: Point) {
        return p.x >= b.x && p.y >= b.y && p.x < b.x + b.w && p.y < b.y + b.h;
    }

    public static toSegments(box: Box): Segment[] {
        const { x, y } = box;
        const x2 = x + box.w;
        const y2 = y + box.h;

        const p1 = new Point(x, y);
        const p2 = new Point(x, y2);
        const p3 = new Point(x2, y2);
        const p4 = new Point(x2, y);

        return [
            { from: p1, to: p2 },
            { from: p2, to: p3 },
            { from: p3, to: p4 },
            { from: p4, to: p1 },
        ];
    }

    public static lineIntersections(box: Box, line: Segment) {
        const { x, y } = line.from;

        const dxInv = 1 / (line.to.x - x);
        const tx1 = (box.x - x) * dxInv;
        const tx2 = (box.x + box.w - x) * dxInv;

        let tmin = Math.min(tx1, tx2);
        let tmax = Math.max(tx1, tx2);

        const dyInv = 1 / (line.to.y - y);
        const ty1 = (box.y - y) * dyInv;
        const ty2 = (box.y + box.h - y) * dyInv;

        tmin = Math.max(tmin, Math.min(ty1, ty2));
        tmax = Math.min(tmax, Math.max(ty1, ty2));

        return [tmin, tmax];
    }

    public static lineIntersects(box: Box, line: Segment) {
        const [tmin, tmax] = Box.lineIntersections(box, line);
        return tmax >= tmin;
    }

    public static rayIntersects(box: Box, ray: Segment) {
        const [tmin, tmax] = Box.lineIntersections(box, ray);
        return tmax >= tmin && tmax >= 0;
    }

    public static segmentIntersects(box: Box, segment: Segment) {
        const [tmin, tmax] = Box.lineIntersections(box, segment);
        return tmax >= tmin && (tmin >= 0 || tmax <= 1.0);
    }

    public static crossSection(box: Box, p: Point) {
        const x = box.x;
        const y = box.y;
        const { h, w } = box;
        const dx = x - p.x;
        const dy = y - p.y;
        const octant = 3 * Math.sign(Math.sign(dy) + Math.sign(dy + h)) + Math.sign(Math.sign(dx) + Math.sign(dx + w));

        switch (octant) {
            case 4:
                return new Segment(new Point(x + w, y), new Point(x, y + h));
            case 3:
                return new Segment(new Point(x + w, y), new Point(x, y));
            case 2:
                return new Segment(new Point(x + w, y + h), new Point(x, y));
            case 1:
                return new Segment(new Point(x, y), new Point(x, y + h));
            case -1:
                return new Segment(new Point(x + w, y + h), new Point(x + w, y));
            case -2:
                return new Segment(new Point(x, y), new Point(x + w, y + h));
            case -3:
                return new Segment(new Point(x, y + h), new Point(x + w, y + h));
            case -4:
                return new Segment(new Point(x, y + h), new Point(x + w, y));
            default:
                return null;
        }
    }

    constructor(public x: number, public y: number, public w: number, public h: number) {}
}
