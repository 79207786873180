import { StyleRules, Theme, withStyles } from "@material-ui/core/styles";
import GridIcon from "@material-ui/icons/GridOn";
import DoorIcon from "@material-ui/icons/MeetingRoom";
import TouchIcon from "@material-ui/icons/TouchApp";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Set } from "immutable";
import React from "react";
import { DragElementWrapper, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { ExtendedFirebaseInstance, ExtendedStorageInstance, useFirebase } from "react-redux-firebase";
import * as TorchlitCore from "../../core/Cargo.toml";
import { moveCursor } from "../actions";
import { Dispatch, DoorData, DungeonData, DungeonTokenData, MemberData, VaultTokenData } from "../store";
import DungeonCanvas from "./DungeonCanvas";
import { ClientPoint, Point } from "./lib/geometry";
import { isPointOfView } from "./lib/utils";
import * as Tools from "./tools";

TorchlitCore.init();

interface DungeonProps {
    firebase: ExtendedFirebaseInstance & ExtendedStorageInstance;
    gameId: string;
    dungeonId: string;
    uid: string;
    dungeon: DungeonData;
    members: Record<string, MemberData>;
    doors: Record<string, DoorData>;
    tokens: Record<string, DungeonTokenData>;
    player?: string | boolean;
}

interface ToolPalette {
    select: Tools.SelectTool;
    door: Tools.AddDoorTool;
    grid: Tools.SetGridTool;
}

interface DungeonState {
    quadtree?: TorchlitCore.Quadtree;
    panningOffset: Point;
    scale: number;
    activeTool: keyof ToolPalette;
    showGrid: boolean;
    selection: Set<string>;
    selectedToken?: string;
    hadPointOfView: boolean;
    visibilityUrl?: string;
}

function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const styles = (theme: Theme): StyleRules => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        position: "relative",

        "& > canvas": {
            position: "absolute",
            width: "100%",
            height: "100%",
        },
    },

    toolPalette: {
        position: "absolute",
        top: "1rem",
        left: "1rem",
        backgroundColor: theme.palette.background.default,
    },
});

export class Dungeon extends React.Component<
    DungeonProps & { dropRef: DragElementWrapper<any>; dispatch: Dispatch } & { classes: Record<string, string> },
    DungeonState
> {
    public state: DungeonState = {
        panningOffset: new Point(0, 0),
        scale: 1.0,
        activeTool: "select",
        showGrid: false,
        hadPointOfView: false,
        selection: Set(),
    };

    public canvasRef: React.RefObject<DungeonCanvas> = React.createRef();

    public tools: ToolPalette = {
        select: new Tools.SelectTool(this, this.props.dispatch),
        door: new Tools.AddDoorTool(this, this.props.dispatch),
        grid: new Tools.SetGridTool(this, this.props.dispatch),
    };

    public clientToPoint(p: ClientPoint): Point {
        return this.canvasRef.current?.clientToPoint(p) || { x: 0, y: 0 };
    }

    public snapToGrid(size: Point, p: Point): Point {
        const gridScale = this.props.dungeon.gridScale;
        const gridOffset = this.props.dungeon.gridOffset || { x: 0, y: 0 };
        const wOffset = (1 - (Math.ceil(size.x) % 2)) / 2;
        const hOffset = (1 - (Math.ceil(size.y) % 2)) / 2;

        const x = (Math.round((p.x - gridOffset.x) / gridScale - wOffset) + wOffset) * gridScale + gridOffset.x;
        const y = (Math.round((p.y - gridOffset.y) / gridScale - hOffset) + hOffset) * gridScale + gridOffset.y;

        return { x, y };
    }

    public select(key: string) {
        this.setState({ selection: this.state.selection.add(key) });
    }

    public deselect(key: string) {
        this.setState({ selection: this.state.selection.remove(key) });
    }

    public clearSelection() {
        this.setState({ selection: Set() });
    }

    static getDerivedStateFromProps(props: DungeonProps, state: DungeonState): Partial<DungeonState> {
        const result: Partial<DungeonState> = {};

        let hadPointOfView = state.hadPointOfView;
        if (props.dungeon.visibilityUrl !== state.visibilityUrl) {
            result.visibilityUrl = props.dungeon.visibilityUrl;
            result.quadtree = undefined;
            hadPointOfView = result.hadPointOfView = false;
        }

        if (!hadPointOfView) {
            const token = Object.values(props.tokens).find((t) => isPointOfView(t, props.player));
            if (token != null) {
                result.hadPointOfView = true;
                result.panningOffset = token.pos;
            }
        }

        return result;
    }

    public componentDidMount() {
        this.centerView();
        this.loadQuadtree();
    }

    public componentDidUpdate(prevProps: DungeonProps, prevState: DungeonState) {
        if (prevState.quadtree != null && this.state.quadtree !== prevState.quadtree) {
            prevState.quadtree.free();
        }

        if (prevProps.dungeon.imageUrl !== this.props.dungeon.imageUrl) {
            this.centerView();
        }

        if (prevProps.dungeon.visibilityUrl !== this.props.dungeon.visibilityUrl) {
            this.loadQuadtree();
        }
    }

    public componentWillUnmount() {
        if (this.state.quadtree != null) {
            this.state.quadtree.free();
        }
    }

    public render() {
        const {
            classes,
            dungeon: { imageUrl },
            dropRef,
        } = this.props;

        return (
            <div className={classes.root} ref={dropRef}>
                <DungeonCanvas
                    ref={this.canvasRef}
                    dungeon={this.props.dungeon}
                    doors={this.props.doors}
                    tokens={this.props.tokens}
                    cursors={Object.entries(this.props.members)
                        .filter(([k, v]) => k !== this.props.uid && v.cursor != null)
                        .map(([_, m]) => ({ x: m.cursor!.x, y: m.cursor!.y, color: m.color }))}
                    player={this.props.player}
                    quadtree={this.state.quadtree}
                    zoom={this.state.scale}
                    pan={this.state.panningOffset}
                    showGrid={this.state.showGrid}
                    selection={this.state.selection}
                    canvasProps={{
                        onMouseDown: this.handleMouseDown,
                        onMouseMove: this.handleMouseMove,
                        onMouseUp: this.handleMouseUp,
                        onMouseOut: this.handleMouseOut,
                        onWheel: this.handleWheel,
                        onContextMenu: this.handlceContextMenu,
                    }}
                    touchEvents={{
                        onTouchStart: this.handleTouchStart,
                        onTouchMove: this.handleTouchMove,
                        onTouchEnd: this.handleTouchEnd,
                    }}
                />
                {!this.props.player && (
                    <ToggleButtonGroup
                        size="large"
                        orientation="vertical"
                        value={this.state.activeTool}
                        exclusive
                        classes={{ root: classes.toolPalette }}
                        onChange={(_, val) => {
                            if (val !== this.state.activeTool) {
                                this.tools[this.state.activeTool].deactivate();
                                this.tools[val as keyof ToolPalette].activate();
                                this.setState({ activeTool: val });
                            }
                        }}
                    >
                        <ToggleButton value="select" aria-label="select">
                            <TouchIcon />
                        </ToggleButton>
                        <ToggleButton value="door" aria-label="door">
                            <DoorIcon />
                        </ToggleButton>
                        <ToggleButton value="grid" aria-label="grid">
                            <GridIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            </div>
        );
    }

    private loadQuadtree() {
        fetch(this.props.dungeon.visibilityUrl).then(async (resp) => {
            if (resp.ok) {
                const buffer = await resp.arrayBuffer();
                const quadtree = TorchlitCore.Quadtree.load(new Uint8Array(buffer));
                this.setState({ quadtree });
            }
        });
    }

    private centerView() {
        if (!this.state.hadPointOfView) {
            const url = this.props.dungeon.imageUrl;
            const mapImage = new Image();
            mapImage.src = url;
            mapImage.decode().then(() => {
                const { naturalWidth: width, naturalHeight: height } = mapImage;
                if (!this.state.hadPointOfView && this.props.dungeon.imageUrl === url) {
                    this.setState({ panningOffset: { x: width / 2, y: height / 2 } });
                }
            });
        }
    }

    private handleMouseDown = (ev: React.MouseEvent<HTMLCanvasElement>) => {
        this.tools[this.state.activeTool].handleMouseDown(ev);
    };

    private handleMouseMove = (ev: React.MouseEvent<HTMLCanvasElement>) => {
        this.props.dispatch(moveCursor(this.props.gameId, this.props.uid, this.clientToPoint(ev)));
        this.tools[this.state.activeTool].handleMouseMove(ev);
    };

    private handleMouseUp = (ev: React.MouseEvent<HTMLCanvasElement>) => {
        this.tools[this.state.activeTool].handleMouseUp(ev);
    };

    private handleMouseOut = (ev: React.MouseEvent<HTMLCanvasElement>) => {
        this.props.dispatch(moveCursor(this.props.gameId, this.props.uid, null));
        this.tools[this.state.activeTool].handleMouseOut(ev);
    };

    private handleWheel = (ev: React.WheelEvent<HTMLCanvasElement>) => {
        this.tools[this.state.activeTool].handleWheel(ev);
    };

    private handlceContextMenu = (ev: React.MouseEvent<HTMLCanvasElement>) => {
        this.tools[this.state.activeTool].handleContextMenu(ev);
    };

    private handleTouchStart = (ev: TouchEvent) => {
        if (ev.touches.length === 1) {
            this.props.dispatch(
                moveCursor(this.props.gameId, this.props.uid, this.clientToPoint(ev.changedTouches[0]))
            );
        }
        this.tools[this.state.activeTool].handleTouchStart(ev);
    };

    private handleTouchMove = (ev: TouchEvent) => {
        this.tools[this.state.activeTool].handleTouchMove(ev);
    };

    private handleTouchEnd = (ev: TouchEvent) => {
        if (ev.touches.length === 0) {
            this.props.dispatch(moveCursor(this.props.gameId, this.props.uid, null));
        }
        this.tools[this.state.activeTool].handleTouchEnd(ev);
    };
}

function DungeonWithTokenDrop(props: DungeonProps & { classes: Record<string, string> }) {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const [_, drop] = useDrop({
        accept: "token",
        drop: (item, monitor) => {
            const token: VaultTokenData | undefined = (item as any).token;
            if (token != null && ref.current != null) {
                const dungeon = ref.current;
                const { x: clientX, y: clientY } = monitor.getClientOffset()!;
                const pos = dungeon.snapToGrid(token.size, dungeon.clientToPoint({ clientX, clientY }));
                let re = new RegExp(`${escapeRegExp(token.name)}( - (\\d)+)?`);
                let max = Object.values(props.tokens)
                    .map((x) => re.exec(x.name))
                    .filter((x) => x != null)
                    .reduce((p, x) => Math.max(p, +(x![2] || 1)), 0);

                let name = max > 0 ? `${token.name} - ${max + 1}` : token.name;
                firebase.push(`tokens/${props.dungeonId}`, { ...token, name, pos });
            }
        },
    });

    const ref = React.useRef<Dungeon>(null);

    return <Dungeon {...props} ref={ref} dropRef={drop} dispatch={dispatch} />;
}

export default withStyles(styles)(DungeonWithTokenDrop);
