import { grey } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";

const useStyles = makeStyles(() => ({
    dropzone: {
        backgroundColor: grey[700],
        borderColor: grey[500],
        "& .DropzoneArea-uploadIconSize-250": {
            color: grey[400],
        },
    },
    previewContainer: {
        width: "100%",
        margin: 0,
        justifyContent: "center",
        "& .MuiGrid-item": {
            padding: 0,
        },
    },
}));

export default function GameFields({
    name,
    setName,
    setFile,
}: {
    name: string;
    setName: (name: string) => void;
    setFile: (file: File) => void;
}): JSX.Element {
    const classes = useStyles();

    const handleFileChange = (files: File[]) => {
        if (files.length > 0 && name == "") {
            setName(files[0].name.replace(/\.[^.]+$/, ""));
        }
        setFile(files[0]);
    };

    return (
        <>
            <TextField
                autoFocus
                type="text"
                label="Game name"
                value={name}
                required
                margin="normal"
                fullWidth
                onChange={(ev) => setName(ev.target.value)}
            />
            <FormControl margin="normal" fullWidth>
                <FormLabel>
                    Thumbnail image{" "}
                    <Typography variant="caption" color="primary">
                        Optional
                    </Typography>
                </FormLabel>
                <DropzoneArea
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    maxFileSize={100e6}
                    onChange={handleFileChange}
                    dropzoneClass={classes.dropzone}
                    previewGridClasses={{ container: classes.previewContainer }}
                    showAlerts={false}
                    dropzoneText="Drag and drop an image here or click"
                />
            </FormControl>
        </>
    );
}
