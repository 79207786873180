import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import * as React from "react";
import { useSelector } from "react-redux";
import { isEmpty, useFirebase, useFirebaseConnect } from "react-redux-firebase";
import { Route, Switch } from "react-router-dom";
import { RootState } from "../store";
import logo from "./logo.svg";
import PrivateRoute from "./PrivateRoute";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        toolbar: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        grow: {
            flexGrow: 1,
        },
        logo: {
            margin: 2,
        },
        newMapItem: {
            borderTop: "0.5px solid",
        },
    })
);

function GameMenus({ gameId }: { gameId: string }): JSX.Element | null {
    useFirebaseConnect(`games/${gameId}`);
    const classes = useStyles();

    const game = useSelector(({ firebase: { auth, data, profile } }: RootState) => data.games?.[gameId]);

    return (
        <>
            <div className={classes.grow} />
            {game != null && (
                <Button color="primary" href="/">
                    Leave Game
                </Button>
            )}
        </>
    );
}

export default function Header() {
    const firebase = useFirebase();
    const auth = useSelector((state: RootState) => state.firebase.auth);
    const classes = useStyles();

    function loginWithGoogle() {
        return firebase.login({ provider: "google", type: "popup" });
    }

    function logout() {
        return firebase.logout();
    }

    const loggedIn = !isEmpty(auth);

    return (
        <div>
            <AppBar className={classes.appbar} position="static" color="secondary">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <img src={logo} alt="The Torchlit Tabletop" />
                    </div>
                    <Switch>
                        <PrivateRoute
                            path="/games/:gameId"
                            fallback={<div className={classes.grow} />}
                            render={({ match }) => <GameMenus gameId={match.params.gameId} />}
                        />
                        <Route>
                            <div className={classes.grow} />
                        </Route>
                    </Switch>
                    {!loggedIn ? (
                        <Button variant="contained" color="primary" onClick={loginWithGoogle}>
                            Sign in
                        </Button>
                    ) : (
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            // aria-controls={menuId}
                            // aria-haspopup="true"
                            // onClick={handleProfileMenuOpen}
                            color="inherit"
                            onClick={logout}
                        >
                            <AccountCircle />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
