import { DungeonTokenData } from "../../store";

export function isGameMaster(player?: string | boolean) {
    return !player;
}

export function isPlayer(player?: string | boolean) {
    return !isGameMaster(player);
}

export function isPointOfView(token: DungeonTokenData, player?: string | boolean): boolean {
    return !!token.hasSight && !!token.player && canControl(token, player);
}

export function canControl(token: DungeonTokenData, player?: string | boolean): boolean {
    return (
        isGameMaster(player) ||
        (!!token.player && (player === true || token.player === true || token.player === player))
    );
}
