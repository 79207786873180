import { encode } from "firebase-key";
import { History } from "history";
import { nanoid } from "nanoid";
import {
    actionTypes,
    ExtendedFirebaseInstance,
    ExtendedAuthInstance,
    ExtendedStorageInstance,
} from "react-redux-firebase";
import { Dispatch } from "redux";
import * as TorchlitCore from "../../core/Cargo.toml";
import { AlertSeverity, DungeonData, GameData, ModalProps, VaultTokenData } from "../store";
import Throttle from "./throttle";
import {
    HideAlertAction,
    HideModalAction,
    RemoveAlertAction,
    RemoveModalAction,
    ShowAlertAction,
    ShowModalAction,
    ThunkAction,
} from "./types";

export const showModal = <K extends keyof ModalProps>(modalType: K, props: ModalProps[K]): ShowModalAction<K> => ({
    type: "SHOW_MODAL",
    modalType,
    props,
});

export const hideModal = (): HideModalAction => ({
    type: "HIDE_MODAL",
});

export const removeModal = (): RemoveModalAction => ({
    type: "REMOVE_MODAL",
});

export const showAlert = (severity: AlertSeverity, message: string): ShowAlertAction => ({
    type: "SHOW_ALERT",
    key: nanoid(),
    severity,
    message,
});

export const hideAlert = (key: string): HideAlertAction => ({ type: "HIDE_ALERT", key });

export const removeAlert = (key: string): RemoveAlertAction => ({ type: "REMOVE_ALERT", key });

async function buildQuadtree(file?: File, progress?: (n: number) => void): Promise<Uint8Array> {
    const url = file && URL.createObjectURL(file);
    const img = new Image();

    if (url != null) {
        img.src = url;
        await img.decode();
    }

    let { naturalWidth: width, naturalHeight: height } = img;
    width = Math.max(width, 1);
    height = Math.max(height, 1);

    const blockSize = TorchlitCore.Quadtree.block_size();
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = blockSize;
    offscreenCanvas.height = blockSize;

    const ctx = offscreenCanvas.getContext("2d");
    if (!ctx) {
        throw new Error("Failed to get offscreen canvas context");
    }
    ctx.fillStyle = "#ffffff";

    const quadtreeSize = 1 << Math.ceil(Math.max(Math.log2(width), Math.log2(height)));
    const blockCount = Math.pow(quadtreeSize / blockSize, 2);
    let blocksProcessed = 0;

    console.time("Quadtree.init");
    const quadtree = new TorchlitCore.Quadtree(
        width,
        height,
        (x: number, y: number): Uint8ClampedArray => {
            ctx.fillRect(0, 0, blockSize, blockSize);
            ctx.drawImage(img, x, y, blockSize, blockSize, 0, 0, blockSize, blockSize);
            progress?.(blocksProcessed++ / blockCount);
            return ctx.getImageData(0, 0, blockSize, blockSize).data;
        }
    );
    console.timeEnd("Quadtree.init");

    if (url != null) {
        URL.revokeObjectURL(url);
    }

    return quadtree.serialize();
}

async function uploadDungeon(
    firebase: ExtendedFirebaseInstance & ExtendedAuthInstance & ExtendedStorageInstance,
    uid: string,
    gameId: string,
    name: string,
    image: File,
    visibility?: File
): Promise<string> {
    const ext = image.name.match(/\.[^.]+$/)?.[0] || "";
    const basename = nanoid();
    const promises: [Promise<string>, Promise<string>] = [
        firebase
            .storage()
            .ref(`${uid}/dungeons/${basename}${ext}`)
            .put(image)
            .then((snapshot) => snapshot.ref.getDownloadURL()),
        buildQuadtree(visibility)
            .then((quadtree) => firebase.storage().ref(`${uid}/dungeons/${basename}.vis`).put(quadtree))
            .then((snapshot) => snapshot.ref.getDownloadURL()),
    ];
    const [imageUrl, visibilityUrl] = await Promise.all(promises);

    const dungeon: DungeonData = {
        game_id: gameId,
        name,
        imageUrl,
        visibilityUrl,
        gridScale: 50,
        gridOffset: { x: 35, y: 35 },
    };

    const data = await firebase.push("/dungeons", dungeon);
    await firebase.set(`/games/${gameId}/dungeons/${data.key}`, name);
    return data.key!;
}

export const createDungeon = (
    gameId: string,
    name: string,
    image: File,
    visibility?: File,
    history?: History
): ThunkAction<void> => {
    return (dispatch, getState, getFirebase) => {
        const uid = getState().firebase.auth.uid;
        const firebase = getFirebase();

        dispatch({ type: "SHOW_PROGRESS" });
        (async () => {
            try {
                const dungeonId = await uploadDungeon(firebase, uid, gameId, name, image, visibility);
                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("success", "Map created"));
                hideModal();
                if (history != null) {
                    history.push(`/games/${gameId}/d/${dungeonId}`);
                }
            } catch (err) {
                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("error", err.toString()));
            }
        })();
    };
};

export const createGame = (
    gameName: string,
    gameFile: File | null,
    dungeonName: string,
    dungeonImage: File,
    dungeonVisibility?: File,
    history?: History
): ThunkAction<void> => {
    return (dispatch, getState, getFirebase) => {
        const {
            auth: { uid },
            profile,
        } = getState().firebase;
        const firebase = getFirebase();

        const uploadThumbnail = (file: File | null, fallback: string = ""): Promise<string> => {
            if (file == null) {
                return Promise.resolve(fallback);
            }

            const ext = file.name.match(/\.[^.]+$/)?.[0] || "";
            const basename = nanoid();
            return firebase
                .storage()
                .ref(`${uid}/games/${basename}${ext}`)
                .put(file)
                .then((snapshot) => snapshot.ref.getDownloadURL());
        };

        dispatch({ type: "SHOW_PROGRESS" });
        (async () => {
            try {
                const thumbnailUrl = await uploadThumbnail(
                    gameFile,
                    "https://firebasestorage.googleapis.com/v0/b/torchlit-197721.appspot.com/o/generic-game-thumbnail.jpg?alt=media&amp;token=9db24ac2-033b-40a7-8aa1-bccbf33f5730"
                );

                const game: Partial<GameData> = {
                    name: gameName,
                    owner: uid,
                    thumbnailUrl,
                };

                const gameSnapshot = await firebase.push("/games", game);

                const dungeonId = await uploadDungeon(
                    firebase,
                    uid,
                    gameSnapshot.key!,
                    dungeonName,
                    dungeonImage,
                    dungeonVisibility
                );

                await firebase.update(`/games/${gameSnapshot.key}`, {
                    activeDungeon: dungeonId,
                });

                await firebase.set(`/members/${gameSnapshot.key}/${uid}`, {
                    displayName: profile.displayName,
                    avatarUrl: profile.avatarUrl,
                    color: profile.preferredColor,
                });

                await firebase.set(`/users/${uid}/games/${gameSnapshot.key}`, true);

                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("success", "Game created"));
                hideModal();
                if (history != null) {
                    history.push(`/games/${gameSnapshot.key}`);
                }
            } catch (err) {
                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("error", err.toString()));
            }
        })();
    };
};

export const createToken = (
    folderName: string,
    name: string,
    file: File,
    size: number,
    hasSight: boolean,
    darkvision: number,
    emitsLight: boolean,
    brightRadius: number,
    dimRadius: number,
    lightColor: string
): ThunkAction<void> => {
    return (dispatch, getState, getFirebase) => {
        const uid = getState().firebase.auth.uid;
        const firebase = getFirebase();

        const ext = file.name.match(/\.[^.]+$/)?.[0] || "";
        const basename = nanoid();
        dispatch({ type: "SHOW_PROGRESS" });
        (async () => {
            try {
                const snapshot = await firebase.storage().ref(`${uid}/${basename}${ext}`).put(file);
                const url = await snapshot.ref.getDownloadURL();

                const token: VaultTokenData = {
                    imageUrl: url,
                    name,
                    size: { x: size, y: size },
                };

                if (hasSight) {
                    token.hasSight = true;
                    if (darkvision > 0) {
                        token.darkvision = darkvision / 5 + 0.5;
                    }
                }

                if (emitsLight) {
                    token.emitsLight = {
                        bright: brightRadius / 5 + 0.5,
                        dim: dimRadius / 5 + 0.5,
                        color: lightColor,
                    };
                }

                await firebase.push(`/vault/${uid}/tokens/${encode(folderName)}`, token);
                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("success", `Created token for "${name}" in "${folderName}"`));
                hideModal();
            } catch (err) {
                dispatch({ type: "HIDE_PROGRESS" });
                dispatch(showAlert("error", err.toString()));
            }
        })();
    };
};

const moveCursorThrottle = new Throttle(100);

export const moveCursor = (gameId: string, uid: string, cursor: { x: number; y: number } | null): ThunkAction<void> => {
    const path = `members/${gameId}/${uid}/cursor`;

    return (_dispatch, _getState, getFirebase) => {
        moveCursorThrottle.push(() => {
            getFirebase().set(path, cursor);
            // TODO: Error handling
        });
    };
};

const moveTokenThrottle = new Throttle(100);

export const moveToken = (dungeonId: string, tokenId: string, pos: { x: number; y: number }): ThunkAction<void> => {
    const path = `tokens/${dungeonId}/${tokenId}/pos`;

    return (dispatch: Dispatch, _getState, getFirebase) => {
        moveTokenThrottle.push(
            () => {
                getFirebase().set(path, pos);
                // TODO: Error handling
            },
            () => {
                dispatch({
                    type: actionTypes.SET,
                    path: path,
                    data: pos,
                });
            }
        );
    };
};

export const createDoor = (
    dungeonId: string,
    doorId: string,
    from: { x: number; y: number },
    to: { x: number; y: number }
): ThunkAction<void> => {
    const path = `doors/${dungeonId}/${doorId}`;

    return (_dispatch, _getState, getFirebase) => {
        getFirebase().set(path, { from, to });
        // TODO: Error handling
    };
};

const moveDoorThrottle = new Throttle(100);

export const moveDoor = (
    dungeonId: string,
    doorId: string,
    endpoint: "from" | "to",
    pos: { x: number; y: number }
): ThunkAction<void> => {
    const path = `doors/${dungeonId}/${doorId}/${endpoint}`;
    return (dispatch: Dispatch, _, getFirebase) => {
        moveDoorThrottle.push(
            () => {
                getFirebase().set(path, pos);
                // TODO: Error handling
            },
            () => {
                dispatch({
                    type: actionTypes.SET,
                    path: path,
                    data: pos,
                });
            }
        );
    };
};

export const openDoor = (dungeonId: string, doorId: string, open: boolean): ThunkAction<void> => {
    const path = `doors/${dungeonId}/${doorId}/open`;

    return (_dispatch, _getState, getFirebase) => {
        if (open) {
            getFirebase().set(path, true);
            // TODO: Error handling
        } else {
            getFirebase().remove(path);
            // TODO: Error handling
        }
    };
};
