import * as colors from "@material-ui/core/colors";
import { ReactReduxFirebaseConfig } from "react-redux-firebase";

export const firebase = {
    apiKey: "AIzaSyA4SJJuzWTyM68nEGq1QU1EX5Hk_whufbk",
    authDomain: "app.torchlit.io",
    databaseURL: "https://torchlit-197721.firebaseio.com",
    projectId: "torchlit-197721",
    storageBucket: "torchlit-197721.appspot.com",
    messagingSenderId: "386535454594",
};

export const reduxFirebase: Partial<ReactReduxFirebaseConfig> = {
    userProfile: "users",
    updateProfileOnLogin: false,
    profileFactory: (_, profile) => {
        if (profile.displayName == null) {
            profile.displayName = profile.email || "Anonymous";
        }

        if (profile.preferredColor == null) {
            const colorNames = [
                "amber",
                "blue",
                "blueGrey",
                "brown",
                "cyan",
                "deepOrange",
                "deepPurple",
                "green",
                "indigo",
                "lightBlue",
                "lightGreen",
                "lime",
                "orange",
                "pink",
                "purple",
                "red",
                "teal",
                "yellow",
            ];
            const color: any = (colors as any)[colorNames[Math.floor(Math.random() * colorNames.length)]];
            profile.preferredColor = color[500];
        }

        return profile;
    },
};
