import * as React from "react";
import { Switch } from "react-router-dom";
import "./App.css";
import Alerts from "./components/Alerts";
import GameLoader from "./components/GameLoader";
import Header from "./components/Header";
import Home from "./components/Home";
import PrivateRoute from "./components/PrivateRoute";
import Progress from "./components/Progress";
import Modals from "./modals";

export default function App() {
    return (
        <div className="App">
            <Header />

            <Switch>
                <PrivateRoute exact path="/" render={() => <Home />} />
                <PrivateRoute
                    path="/games/:gameId"
                    render={({ match }) => <GameLoader gameId={match.params.gameId} />}
                />
            </Switch>

            <Modals />
            <Alerts />
            <Progress />
        </div>
    );
}
