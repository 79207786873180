import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Edit from "@material-ui/icons/Edit";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import Color from "color";
import React from "react";
import { MemberData } from "../store";

export default function MembersList({
    gameOwner,
    uid,
    members,
}: {
    gameOwner: boolean;
    uid: string;
    members: Record<string, MemberData>;
}): JSX.Element | null {
    return (
        <List disablePadding>
            {Object.entries(members).map(([key, member]) => (
                <ListItem key={key}>
                    <ListItemAvatar>
                        <Avatar src={member.avatarUrl} />
                    </ListItemAvatar>
                    <ListItemText>
                        <span
                            style={{
                                color: Color(member.color).lighten(0.25).string(),
                                fontWeight: "bold",
                            }}
                        >
                            {member.displayName || "Anonymous"}
                        </span>
                    </ListItemText>
                    {key === uid ? (
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit">
                                <Edit />
                            </IconButton>
                        </ListItemSecondaryAction>
                    ) : (
                        gameOwner && (
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="remove">
                                    <RemoveCircleOutline />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )
                    )}
                </ListItem>
            ))}
        </List>
    );
}
