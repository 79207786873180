export default class Throttle {
    private timer?: number;
    private pending?: () => void;

    constructor(public ms: number) {}

    public push(throttledAction: () => void, fallback?: () => void) {
        if (this.timer != null) {
            this.pending = throttledAction;
            fallback?.();
        } else {
            this.setTimer();
            throttledAction();
        }
    }

    private setTimer() {
        this.timer = window.setTimeout(() => this.callback(), this.ms);
    }

    private callback() {
        this.timer = undefined;
        if (this.pending != null) {
            const action = this.pending;
            this.pending = undefined;
            this.setTimer();
            action();
        }
    }
}
