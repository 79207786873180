import { DataSnapshot } from "@firebase/database-types";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as React from "react";
import { createToken } from "../actions";
import TokenFields, { TokenFieldValues } from "./TokenFields";

const { useState } = React;

export interface CreateTokenDialogProps extends Omit<DialogProps, "children"> {
    uid: string;
    folderNames: string[];
    onCreated?: (data: DataSnapshot) => void;
    onClose?: (ev: {}, reason: "backdropClick" | "escapeKeyDown" | "cancelClick") => void;
}

export default function CreateTokenDialog({
    uid,
    folderNames,
    open,
    onClose,
    onCreated,
    ...props
}: CreateTokenDialogProps): JSX.Element {
    const [fields, setFields] = useState(new TokenFieldValues());

    const onChange = (newFields: TokenFieldValues) => {
        if (fields.image == null && newFields.image != null && newFields.name == "") {
            newFields.name = newFields.image.name.replace(/\.[^.]+$/, "");
        }
        setFields(newFields);
    };

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (TokenFieldValues.isValid(fields) && fields.image != null) {
            createToken(
                fields.folderName,
                fields.name,
                fields.image,
                fields.size,
                fields.hasSight,
                fields.darkvision,
                fields.emitsLight,
                fields.lightRadii[0],
                fields.lightRadii[1],
                fields.lightColor
            );
        }
    };

    return (
        <Dialog open={open} onClose={onClose} {...props}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>New Token</DialogTitle>
                <DialogContent>
                    <TokenFields folderNames={folderNames} fields={fields} onChange={onChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(ev) => onClose && onClose(ev, "cancelClick")} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={!TokenFieldValues.isValid(fields)}>
                        Create token
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
