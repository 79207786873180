import Checkbox from "@material-ui/core/Checkbox";
import grey from "@material-ui/core/colors/grey";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";

const useStyles = makeStyles(() => ({
    dropzone: {
        backgroundColor: grey[700],
        borderColor: grey[500],
        "& .DropzoneArea-uploadIconSize-250": {
            color: grey[400],
        },
    },
    previewContainer: {
        width: "100%",
        margin: 0,
        justifyContent: "center",
        "& .MuiGrid-item": {
            padding: 0,
        },
    },
}));

export class TokenFieldValues {
    name: string = "";
    folderName: string = "";
    size: number = 1;
    hasSight: boolean = false;
    darkvision: number = 0;
    emitsLight: boolean = false;
    lightColor: string = "#ffa957";
    lightRadii: number[] = [20, 40];
    image?: File;

    static isValid(fields: TokenFieldValues): boolean {
        return fields.name.trim() !== "" && fields.folderName.trim() !== "" && fields.image != null;
    }
}

export interface TokenFieldsProps {
    folderNames: string[];
    fields: TokenFieldValues;

    onChange?: (value: TokenFieldValues) => void;
}

export default function TokenFields({ folderNames, fields, onChange }: TokenFieldsProps): JSX.Element {
    const classes = useStyles();

    return (
        <>
            <TextField
                autoFocus
                type="text"
                name="name"
                label="Token name"
                value={fields.name}
                margin="dense"
                required
                fullWidth
                onChange={(ev) => onChange?.({ ...fields, name: ev.target.value })}
            />
            <Autocomplete
                freeSolo
                selectOnFocus
                handleHomeEndKeys
                options={folderNames}
                onInputChange={(_, value) => onChange?.({ ...fields, folderName: value })}
                inputValue={fields.folderName}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        type="text"
                        name="folderName"
                        label="Folder name"
                        margin="dense"
                        required
                        fullWidth
                    />
                )}
            />
            <FormControl margin="dense" fullWidth>
                <InputLabel>Size</InputLabel>
                <Select
                    fullWidth
                    value={fields.size}
                    onChange={(ev: React.ChangeEvent<{ value: unknown }>) =>
                        onChange?.({ ...fields, size: ev.target.value as number })
                    }
                >
                    <MenuItem value={0.5}>Tiny</MenuItem>
                    <MenuItem value={1}>Small/Medium</MenuItem>
                    <MenuItem value={2}>Large</MenuItem>
                    <MenuItem value={3}>Huge</MenuItem>
                    <MenuItem value={4}>Gargantuan</MenuItem>
                </Select>
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={fields.hasSight}
                        onChange={(ev) => onChange?.({ ...fields, hasSight: ev.target.checked })}
                        name="hasSight"
                    />
                }
                label="Can see?"
            />
            <FormControl margin="dense" fullWidth>
                <Typography color={fields.hasSight ? "textPrimary" : "textSecondary"} variant="caption" gutterBottom>
                    Darkvision
                </Typography>
                <Slider
                    value={fields.darkvision}
                    onChange={(_, val) => onChange?.({ ...fields, darkvision: val as number })}
                    step={5}
                    marks
                    min={0}
                    max={240}
                    disabled={!fields.hasSight}
                    valueLabelDisplay="auto"
                />
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={fields.emitsLight}
                        onChange={(ev) => onChange?.({ ...fields, emitsLight: ev.target.checked })}
                        name="emitsLight"
                    />
                }
                label="Emits light?"
            />
            <TextField
                autoFocus
                type="text"
                name="lightColor"
                label="Light color"
                value={fields.lightColor}
                margin="dense"
                disabled={!fields.emitsLight}
                fullWidth
                onChange={(ev) => onChange?.({ ...fields, lightColor: ev.target.value })}
            />
            <FormControl margin="dense" fullWidth>
                <Typography color={fields.emitsLight ? "textPrimary" : "textSecondary"} variant="caption" gutterBottom>
                    Light radius
                </Typography>
                <Slider
                    value={fields.lightRadii}
                    onChange={(_, val) => onChange?.({ ...fields, lightRadii: val as number[] })}
                    track={false}
                    step={5}
                    marks
                    min={0}
                    max={240}
                    disabled={!fields.emitsLight}
                    valueLabelDisplay="auto"
                />
            </FormControl>
            <FormControl margin="dense" fullWidth>
                <DropzoneArea
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    maxFileSize={1e6}
                    onChange={(files) => onChange?.({ ...fields, image: files[0] })}
                    dropzoneClass={classes.dropzone}
                    previewGridClasses={{ container: classes.previewContainer }}
                    showAlerts={false}
                    dropzoneText="Drag and drop an image here or click"
                />
            </FormControl>
        </>
    );
}
