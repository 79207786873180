import { getFirebase } from "react-redux-firebase";
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

export * from "./reducers";
export * from "./types";

export default function configureStore(initialState: any = {}): Store<any, AnyAction> {
    let composeEnhancers;
    if (process.env.NODE_ENV === "development") {
        composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    } else {
        composeEnhancers = compose;
    }

    const store = createStore(
        rootReducer,
        undefined,
        composeEnhancers(applyMiddleware(thunk.withExtraArgument(getFirebase)))
    );

    if ((module as any).hot) {
        // Enable Parcel hot module replacement for reducers
        (module as any).hot.accept("./reducers", () => {
            const nextRootReducer = require("./reducers"); // eslint-disable-line global-require, @typescript-eslint/no-var-requires
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
