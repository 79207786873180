import { grey } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        backgroundColor: grey[700],
        borderColor: grey[500],
        "& .DropzoneArea-uploadIconSize-250": {
            color: grey[400],
        },
    },
    previewContainer: {
        width: "100%",
        margin: 0,
        justifyContent: "center",
        "& .MuiGrid-item": {
            padding: 0,
        },
    },
    dropzoneContainer: {
        display: "flex",
        margin: `0 -${theme.spacing(1)}px`,
        "& > *": {
            margin: `0 ${theme.spacing(1)}px`,
        },
    },
}));

export class DungeonFieldValues {
    public name: string = "";
    public image?: File;
    public visibility?: File;

    public visibilityValid?: boolean;

    public static isValid(fields: DungeonFieldValues): boolean {
        return (
            fields.name.trim() !== "" &&
            fields.image != null &&
            (fields.visibility == null || fields.visibilityValid === true)
        );
    }
}

export interface DungeonFieldProps {
    fields: DungeonFieldValues;

    onChange?: (val: DungeonFieldValues) => void;
}

export default function DungeonFields({ fields, onChange }: DungeonFieldProps): JSX.Element {
    const classes = useStyles();

    useEffect(() => {
        const { image, visibility } = fields;
        if (image != null && visibility != null && fields.visibilityValid == null) {
            let cancel = false;
            const img = new Image();
            img.src = URL.createObjectURL(image);
            const vis = new Image();
            vis.src = URL.createObjectURL(visibility);
            Promise.all([img.decode(), vis.decode()])
                .then(() => {
                    if (!cancel) {
                        onChange?.({
                            ...fields,
                            visibilityValid:
                                img.naturalWidth === vis.naturalWidth && img.naturalHeight === vis.naturalHeight,
                        });
                    }

                    URL.revokeObjectURL(img.src);
                    URL.revokeObjectURL(vis.src);
                })
                .catch((err) => {
                    URL.revokeObjectURL(img.src);
                    URL.revokeObjectURL(vis.src);
                    console.error(err);
                });
            return () => {
                cancel = true;
            };
        } else {
            return;
        }
    }, [fields]);

    return (
        <>
            <TextField
                autoFocus
                type="text"
                label="Map name"
                value={fields.name}
                required
                margin="normal"
                fullWidth
                onChange={(ev) => onChange?.({ ...fields, name: ev.target.value })}
            />
            <div className={classes.dropzoneContainer}>
                <FormControl margin="normal" fullWidth>
                    <FormLabel>Map image</FormLabel>
                    <DropzoneArea
                        acceptedFiles={["image/*"]}
                        filesLimit={1}
                        maxFileSize={10e6}
                        onChange={(files) =>
                            onChange?.({
                                ...fields,
                                image: files[0],
                                visibilityValid: undefined,
                            })
                        }
                        dropzoneClass={classes.dropzone}
                        previewGridClasses={{ container: classes.previewContainer }}
                        showAlerts={false}
                        dropzoneText="Drag and drop an image here or click"
                    />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <FormLabel>Visibility mask</FormLabel>
                    <DropzoneArea
                        acceptedFiles={["image/*"]}
                        filesLimit={1}
                        maxFileSize={10e6}
                        onChange={(files) =>
                            onChange?.({
                                ...fields,
                                visibility: files[0],
                                visibilityValid: undefined,
                            })
                        }
                        dropzoneClass={classes.dropzone}
                        previewGridClasses={{ container: classes.previewContainer }}
                        showAlerts={false}
                        dropzoneText="Drag and drop an image here or click"
                    />
                </FormControl>
            </div>
        </>
    );
}
