import Avatar from "@material-ui/core/Avatar";
import { CollapseProps } from "@material-ui/core/Collapse";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { decode } from "firebase-key";
import React from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { showModal } from "../actions";
import { RootState, VaultTokenData } from "../store";

const useStyles = makeStyles((theme: Theme) => ({
    addItem: {
        position: "sticky",
        // this is theme.shadows[1] with increased negative spread and vertical offset so it is only visible
        // below the box, not to the sides
        boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 2px 1px -1px rgba(0,0,0,0.14),0px 4px 3px -3px rgba(0,0,0,0.12)",
    },
    tokenAvatar: {
        cursor: "grab",
    },
    list: {
        flexGrow: 1,
        overflowY: "auto",
    },
    listItem: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
}));

function DraggableToken({ token, style }: { token: VaultTokenData; style?: React.CSSProperties }): JSX.Element | null {
    const classes = useStyles();
    const [_, drag] = useDrag({
        item: { type: "token", token },
        options: { dropEffect: "copy" },
    });

    return (
        <ListItem ContainerProps={{ style }}>
            <ListItemAvatar ref={drag} className={classes.tokenAvatar}>
                <Avatar src={token.imageUrl} />
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.listItem }}>{token.name}</ListItemText>
            <ListItemSecondaryAction>
                <IconButton edge="end">
                    <Edit />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function TokenFolderList({
    tokens,
    ...rest
}: { tokens: Record<string, VaultTokenData> } & CollapseProps): JSX.Element | null {
    const classes = useStyles();
    const tokenEntries = tokens ? Object.entries(tokens).sort((a, b) => a[1].name.localeCompare(b[1].name)) : [];
    if (tokenEntries.length <= 10) {
        return (
            <List className={classes.list} disablePadding>
                {tokenEntries.map(([key, token]) => (
                    <DraggableToken key={key} token={token} />
                ))}
            </List>
        );
    } else {
        const renderRow = ({ index, style }: ListChildComponentProps) => {
            return <DraggableToken token={tokenEntries[index][1]} style={style} />;
        };
        const itemKey = (index: number) => tokenEntries[index][0];

        return (
            <FixedSizeList height={560} width="100%" itemSize={56} itemCount={tokenEntries.length} itemKey={itemKey}>
                {renderRow}
            </FixedSizeList>
        );
    }
}

export default function TokensList({ uid }: { uid: string }): JSX.Element | null {
    const classes = useStyles();
    useFirebaseConnect(`vault/${uid}/tokens`);
    const folders = useSelector(({ firebase: { data } }: RootState) => data.vault?.[uid]?.tokens);
    const dispatch = useDispatch();

    return (
        <>
            <List disablePadding>
                <ListItem
                    button
                    className={classes.addItem}
                    onClick={() =>
                        dispatch(showModal("CREATE_TOKEN", { uid, folderNames: Object.keys(folders || {}) }))
                    }
                >
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText>New token</ListItemText>
                </ListItem>
            </List>

            <div className={classes.list}>
                {folders &&
                    Object.entries(folders)
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([key, tokens]) => (
                            <ExpansionPanel key={key}>
                                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                    <Typography>{decode(key)}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <TokenFolderList tokens={tokens} />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
            </div>
        </>
    );
}
