import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createGame } from "../actions";
import DungeonFields, { DungeonFieldValues } from "./DungeonFields";
import GameFields from "./GameFields";

const { useState } = React;

export interface CreateGameDialogProps extends Omit<DialogProps, "children"> {
    onClose?: (ev: {}, reason: "backdropClick" | "escapeKeyDown" | "cancelClick") => void;
}

export default function CreateGameDialog(props: CreateGameDialogProps): JSX.Element {
    const dispatch = useDispatch();
    const history = useHistory();

    const [activeStep, setActiveStep] = useState(0);
    const [gameName, setGameName] = useState("");
    const [gameFile, setGameFile] = useState<File | null>(null);
    const [dungeonFields, setDungeonFields] = useState(new DungeonFieldValues());

    const stepValid = activeStep === 0 ? gameName.trim() != "" : DungeonFieldValues.isValid(dungeonFields);

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (!stepValid) {
            return;
        } else if (activeStep < 1) {
            setActiveStep(activeStep + 1);
            return;
        }

        dispatch(
            createGame(gameName, gameFile, dungeonFields.name, dungeonFields.image!, dungeonFields.visibility, history)
        );
    };

    return (
        <Dialog {...props} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
                <DialogTitle>New Game</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep}>
                        <Step key="gameStep" completed={activeStep > 0}>
                            <StepLabel>Basic game info</StepLabel>
                        </Step>
                        <Step key="dungeonStep" completed={activeStep > 1}>
                            <StepLabel>Your first map</StepLabel>
                        </Step>
                    </Stepper>
                    {activeStep === 0 && <GameFields name={gameName} setName={setGameName} setFile={setGameFile} />}
                    {activeStep === 1 && <DungeonFields fields={dungeonFields} onChange={setDungeonFields} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(ev) => props.onClose && props.onClose(ev, "cancelClick")} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => setActiveStep(activeStep - 1)} color="primary" disabled={activeStep === 0}>
                        Back
                    </Button>
                    <Button type="submit" color="primary" disabled={!stepValid}>
                        {activeStep === 1 ? "Create game" : "Next"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
