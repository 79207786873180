import { Dispatch } from "../../store";
import { Dungeon } from "../Dungeon";
import AddDoorTool from "./AddDoorTool";
import DungeonTool from "./DungeonTool";
import SelectTool from "./SelectTool";
import SetGridTool from "./SetGridTool";

export interface ToolConstructor {
    new (view: Dungeon, dispatch: Dispatch): DungeonTool;
}

export { DungeonTool, AddDoorTool, SelectTool, SetGridTool };
